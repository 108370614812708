import React from 'react';

function Iframe({ src }) {
  return (
    <iframe
      title="Public Page"
      src={src}
      style={{ 
        width: '84%',        // Ocupa el 75% del ancho de la página
        height: '100vh',     // Ocupa el 100% de la altura de la ventana
        border: 'none', 
        float: 'right'       // Alineado a la derecha
      }}
    />
  );
}

export default Iframe;
