import React, { useState } from "react";
import Nav from 'react-bootstrap/Nav';
import { Col, Card, Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';

const Header = ({ title }) => {
    return (
        <h2 style={{ textAlign: 'center', margin: '10px', backgroundColor: '#fff', color: '#000', height: '10vh', lineHeight: '10vh', marginTop: '20px' }}>{title}</h2>
    );
};

const BestCryptoTools = ({ data1, data2 }) => {
    const [activeKey, setActiveKey] = useState('#info');

    const handleSelect = (selectedKey) => {
        setActiveKey(selectedKey);
    };

    if (!data1 || !data2) {
        return <p>Cargando datos...</p>;
    }

    return (
        <div style={{ height: '80vh', width: '78vw', margin: '0 auto', marginLeft: '240px', backgroundColor: '#fff' }}>
            <Header title={data1.title} />
            <Card style={{ height: '100%', width: '100%', border: 'none', boxShadow: 'none', backgroundColor: '#fff', marginTop: '20px' }}>
                <Card.Header style={{ backgroundColor: "#fff", textAlign: "center", fontSize: '1.2rem', borderBottom: 'none', display: 'flex', justifyContent: 'center' }}>
                    <Nav variant="tabs" activeKey={activeKey} onSelect={handleSelect} style={{ display: 'flex', width: '100%', borderBottom: '1px solid #ccc' }}>
                        <Nav.Item style={{ flex: 1, textAlign: 'center' }}>
                            <Nav.Link
                                eventKey="#info"
                                style={{
                                    fontSize: '1.5rem',
                                    color: activeKey === '#info' ? '#007bff' : '#000',
                                    border: activeKey === '#info' ? '2px solid #007bff' : '1px solid #ccc',
                                    backgroundColor: activeKey === '#info' ? '#e6f7ff' : '#fff',
                                    display: 'block',
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                Información
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ flex: 1, textAlign: 'center' }}>
                            <Nav.Link
                                eventKey="#tools"
                                style={{
                                    fontSize: '1.5rem',
                                    color: activeKey === '#tools' ? '#ff5722' : '#000',
                                    border: activeKey === '#tools' ? '2px solid #ff5722' : '1px solid #ccc',
                                    backgroundColor: activeKey === '#tools' ? '#ffece6' : '#fff',
                                    display: 'block',
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                Crypto Herramientas
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>

                <Card.Body style={{ height: '60vh', padding: '40px', overflowY: 'auto', marginTop: '20px' }}>
                    {activeKey === '#info' && (
                        <>

                            {data1.sections.map((section, index) => (
                                <div key={index} style={{ marginBottom: "20px", textAlign: "left", marginBottom: '15px' }}>
                                    <Card.Subtitle style={{ fontSize: '2.2rem', textAlign: 'left', marginBottom: '10px', marginBottom: '15px' }}>{section.subtitle}</Card.Subtitle>
                                    <Card.Text style={{ fontSize: '1.5rem', textAlign: 'justify', marginBottom: '20px' }}>{section.content}</Card.Text>
                                </div>
                            ))}

                        </>
                    )}

                    {activeKey === '#tools' && (
                        <Row>
                            {data2.map((exchange, index) => (
                                <Col key={index} sm={6} md={4} style={{ marginBottom: '20px' }}>
                                    <Card style={{ height: '100%', width: '78%', border: 'none', backgroundColor: '#fff', marginTop: '20px' }}>
                                        <Card.Img
                                            variant="top"
                                            src={exchange.icon}
                                            style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                                        />
                                        <Card.Body style={{ textAlign: 'center' }}>
                                            <Card.Title style={{ fontSize: '2.0rem', marginBottom: '10px', textAlign: 'left' }}>
                                                {exchange.name}
                                            </Card.Title>
                                            <Card.Text style={{ fontSize: '1.2rem', textAlign: 'left' }}>
                                                {exchange.description}
                                            </Card.Text>
                                            <Button
                                                variant="link"
                                                style={{ textDecoration: 'none' }}
                                                href={exchange.link}
                                                target="_blank"

                                            >
                                                LEARN MORE
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

export default BestCryptoTools;
