import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import authReducer, { logout } from './frontend/components/reducers/authReducer';
import courseReducer from './frontend/components/reducers/courseReducer';
import moduleReducer from './frontend/components/reducers/moduleReducer';
import articlesReducer from './frontend/components/reducers/articlesReducer';
import articlePage from './frontend/components/reducers/articlePageReducer';
import notificationsReducer from './frontend/components/reducers/notificationsReducer';
import lectureReducer from './frontend/components/reducers/lectureReducer';
import lectureCommentsReducer from './frontend/components/reducers/lectureCommentsReducer';
import discussionReducer from './frontend/components/reducers/discussionReducer';
import announcementsReducer from './frontend/components/reducers/announcementsReducer';
import axios from 'axios';
import examReducer from './frontend/components/reducers/examReducer';
import assessmentCreationReducer from './frontend/components/reducers/assessmentCreationReducer';
import assessmentTakingReducer from './frontend/components/reducers/assessmentTakingReducer';
import submissionsReducer from './frontend/components/reducers/submissionsReducer';
import gradebookReducer from './frontend/components/reducers/gradebookReducer';
import summaryGradebookReducer from './frontend/components/reducers/summaryGradebookReducer';
import participantsReducer from './frontend/components/reducers/participantsReducer';
import assignmentReducer from './frontend/components/reducers/assignmentReducer';
import calendarReducer from './frontend/components/reducers/calendarReducer';
import deadlinesReducer from './frontend/components/reducers/deadlinesReducer';
import courseSettingsReducer from './frontend/components/reducers/courseSettingsReducer';
import achievementsReducer from './frontend/components/reducers/achievementsReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'auth',
    'assessmentCreation',
    'assessmentTaking',
    'submissions',
    'courseParticipants',
    'summaryGradebook',
    'courseGradebook',
    'modules'
  ]
};

const reducer = combineReducers({
  auth: authReducer,
  courses: courseReducer,
  modules: moduleReducer,
  articles: articlesReducer,
  articlePage: articlePage,
  notifications: notificationsReducer,
  lectures: lectureReducer,
  lectureComments: lectureCommentsReducer,
  discussions: discussionReducer,
  announcements: announcementsReducer,
  exams: examReducer,
  assessmentCreation: assessmentCreationReducer,
  assessmentTaking: assessmentTakingReducer,
  submissions: submissionsReducer,
  courseGradebook: gradebookReducer,
  summaryGradebook: summaryGradebookReducer,
  courseParticipants: participantsReducer,
  assignments: assignmentReducer,
  calendar: calendarReducer,
  deadlines: deadlinesReducer,
  courseSettings: courseSettingsReducer,
  achievements: achievementsReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

// Configuración manual para las DevTools de Redux
const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Crear store con DevTools y middlewares
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, logger))
);

let persistor = persistStore(store);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch(logout());
    } else {
      return Promise.reject(error);
    }
  }
);

export { store, persistor };
