import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WalletRegisterForm from './WalletRegisterForm'; // Asegúrate de que la ruta sea correcta
import { RegisterForm } from './RegistrationForm';
import Layout from "../../components/layout/Layout"



const RegistrationCard = () => {
    const [activeForm, setActiveForm] = useState('code'); // 'code' o 'wallet'
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/kits');
    };

    return (
        <Layout headerStyle={1} footerStyle={1} pageCls="my-layout-container">




            <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
                <div className="registration-card">
                    <h2 className="registration-title">Registro en la Plataforma</h2>

             
                    <div className="form-container">
                        {activeForm === 'code' ? <RegisterForm /> : <WalletRegisterForm />}
                    </div>

                </div>
            </div>
        </Layout>
    );
};

export default RegistrationCard;
