import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CryptoCalculator = () => {
  const [btcPrice, setBtcPrice] = useState({ USD: 0, EUR: 0, MXN: 0 });
  const [formData, setFormData] = useState({
    boughtPrice: '',
    amountBought: '',
  });
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await axios.get(
          'https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest',
          {
            params: {
              symbol: 'BTC',
              convert: 'USD,EUR,MXN',
            },
            headers: {
              'X-CMC_PRO_API_KEY': 'TU_API_KEY_AQUI',
            },
          }
        );
        const prices = response.data.data.BTC.quote;
        setBtcPrice({
          USD: prices.USD.price,
          EUR: prices.EUR.price,
          MXN: prices.MXN.price,
        });
      } catch (error) {
        console.error('Error fetching data from CoinMarketCap API', error);
      }
    };
    fetchPrices();
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { boughtPrice, amountBought } = formData;
    if (boughtPrice && amountBought) {
      const gainOrLoss = (btcPrice.USD - parseFloat(boughtPrice)) * parseFloat(amountBought);
      setResult(gainOrLoss.toFixed(2));
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.calculatorBox}>
        <h2 style={styles.title}>Bitcoin Calculator</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>BTC/USD when bought:</label>
            <input
              type="number"
              name="boughtPrice"
              value={formData.boughtPrice}
              onChange={handleInputChange}
              placeholder="Price in USD"
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>BTC/USD today:</label>
            <input
              type="number"
              value={btcPrice.USD}
              readOnly
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>How many BTC did you buy?</label>
            <input
              type="number"
              name="amountBought"
              value={formData.amountBought}
              onChange={handleInputChange}
              placeholder="Amount of BTC"
              style={styles.input}
            />
          </div>
          <button type="submit" style={styles.button}>OK</button>
        </form>
        {result !== null && <p style={styles.result}>Your gain/loss is: ${result}</p>}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'white',
    paddingLeft: '250px', // Añadido para mover a la derecha
  },
  calculatorBox: {
    backgroundColor: 'white',
    padding: '40px',
    borderRadius: '10px',
    width: '60%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '24px',
    color: '#333',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '5px',
    fontSize: '16px',
    color: '#333',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '100%',
    color: '#000', // Cambiado el color del texto a negro
  },
  button: {
    padding: '10px',
    backgroundColor: '#1c7c54', // Cambiado a un tono verde acorde a tu página
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    alignSelf: 'center',
    width: '100px',
  },
  result: {
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '18px',
    color: '#333',
  },
};




export default CryptoCalculator;
