import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Roadmap from '../roadmap/Roadmap';


import CardContainer from './cardBasicFomation/CardContainer';
import Layout from "../../components/layout/Layout";

const Section = styled.section`
  margin-top: 10%;
  padding-bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 90%;
  text-align: justify;
`;

const Heading = styled.h1`
  font-size: 4em;
  margin-bottom: 20px;
  color: black;
  width: 100%;
  font-weight: bold;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 1.8em;
  color: black;
  margin-bottom: 20px;
  text-align: justify;
`;

const ListTitle = styled.h2`
  font-size: 2.5em;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  color:#000;
  text-align: center;
`;

const ListItem = styled.li`
  font-size: 1.8em;
  color: black;
  margin-bottom: 10px;
  text-align: justify;
  color:#000;

  &::before {
    content: '• '; /* Agregar viñetas */
    font-weight: bold;
    color: black;
  }
`;

const UnorderedList = styled.ul`
  list-style-position: inside;
  text-align: justify;
  padding: 0;
  font-weight: bold; /* Negrita para los títulos */

  li {
    font-weight: normal;
    color: grey; /* Gris para los subtítulos */
    font-weight: bold; /* Subtítulos en negrita */
  }
`;

const InfoContainer = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
`;

const CardContainerGrey = styled(InfoContainer)`
  background-color: #f9f9f9; /* Fondo gris claro */
`;

const FAQWithHours = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Layout headerStyle={1} footerStyle={1}>
      <Section>
        <Container>

          {/* Sección Principal */}
          <CardContainerGrey>
            <Heading>Dominando Blockchain: Formación Integral en Finanzas y Desarrollo</Heading>
            <Paragraph>Al finalizar esta formación integral, habrás adquirido un dominio completo de los principios y aplicaciones de la Tecnología Blockchain. Serás capaz de desarrollar y crear aplicaciones Blockchain, comprender y escribir contratos inteligentes, explorar redes escalables como Polygon o Binance Smart Chain, y conceptualizar proyectos reales utilizando Python y Ethereum. Además, adquirirás conocimientos sólidos en Solidity, seguridad y finanzas descentralizadas.</Paragraph>
          </CardContainerGrey>

          {/* Nivel de Profundidad */}
          <CardContainerGrey>
            <ListTitle><strong>Nivel de Profundidad</strong></ListTitle>
            <Paragraph>Este curso ofrece un enfoque integral, desde lo más básico hasta niveles avanzados. La profundidad de la cobertura se adapta para proporcionar un aprendizaje completo y accesible.</Paragraph>
          </CardContainerGrey>

          {/* Audiencia Objetivo */}
          <CardContainerGrey>
            <ListTitle><strong>Audiencia Objetivo</strong></ListTitle>
            <Paragraph>Este programa de formación está diseñado para abogados, fiscales, contables, economistas, asesores, gestores, empresarios y estudiantes de diversos campos, incluyendo informática y finanzas, que deseen sumergirse en el mundo de las blockchains.</Paragraph>
          </CardContainerGrey>

          {/* Formación Básica */}
          <CardContainerGrey>
            <ListTitle><strong>Formación Básica</strong></ListTitle>
            <CardContainer />
          </CardContainerGrey>

          {/* Ruta de Aprendizaje */}
          <CardContainerGrey>
            <ListTitle><strong>Ruta de Aprendizaje</strong></ListTitle>
            <Roadmap />
          </CardContainerGrey>

          {/* Detalles del Programa */}
          <InfoContainer>
            <ListTitle><strong>Detalles del Programa</strong></ListTitle>
            <Paragraph><strong>Duración:</strong> 3 meses</Paragraph>
            <Paragraph><strong>Valor Curricular:</strong> 114 hrs - Certificado: Expedido por UVEG y EDUCA, con valor curricular de 114 hrs descargable.</Paragraph>
            <Paragraph><strong>Metodología:</strong></Paragraph>
            <UnorderedList>
              <ListItem>70 hrs de formación asincrónica sobre Blockchain</ListItem>
              <ListItem>24 hrs sincrónicas, 2 horas por semana de 19:00 a 21:00 horas </ListItem>
              <ListItem>20 hrs de formación asincrónica sobre neuroeconomía</ListItem>
            </UnorderedList>
          </InfoContainer>

        </Container>
      </Section>
    </Layout>
  );
};

export default FAQWithHours;
