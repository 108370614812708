
import Layout from "../components/layout/Layout"
import FeaturedSlider1 from "../components/slider/FeaturedSlider1"
import { SphereToPlaneWithRotation } from "../components/animation/EarthScene"
import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';


export default function Home() {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [esPantallaPequena, setEsPantallaPequena] = useState(window.innerWidth < 600);

    useEffect(() => {
        function manejarCambioDeTamano() {
            setEsPantallaPequena(window.innerWidth < 600);
        }

        window.addEventListener('resize', manejarCambioDeTamano);
        return () => window.removeEventListener('resize', manejarCambioDeTamano);
    }, []);

    // Estilos responsivos basados en el estado
    const estilos = {
        color: 'black',
        display: 'block',
        textAlign: esPantallaPequena ? 'left' : 'justify',
        marginTop: '20px',
    };


    return (
        <>

            <Layout headerStyle={1} footerStyle={1}>

            <div style={{ width: '100vw', display: 'flex', flexDirection: 'column',marginTop:'5%' }}>
                    <div className="tf-section-2 product-detail">
                        <div className="themesflat-container">

                            <div className="col-12">
                                <h1 style={{
                                    color: 'black',
                                    fontFamily: 'Univers, sans-serif',
                                    fontSize: '40px',
                                    margin: '0',
                                    textShadow: '2px 2px 2px #94939A',
                                    textAlign: 'center', // Centrar el texto
                                    marginBottom: isMobile ? '10px' : '20px', // Ajustar el margen inferior en dispositivos móviles
                                }}>
                                    <span style={{ color: 'black' }}>NFT's </span>
                                </h1>
                                <br />


                            </div>


                            <Canvas
                                style={{
                                    width: '100%',
                                    height: isMobile ? '80vh' : '80vh', // Ajustar la altura en dispositivos móviles según tus necesidades
                                    marginTop: isMobile ? '-10vh' : '0', // Ajustar el margen superior en dispositivos móviles
                                }}
                                camera={{
                                    fov: isMobile ? 75 : 75,
                                    near: 0.1,
                                    far: 50,
                                    position: isMobile ? [0, 0, 8] : [2, 0, 5], // Ajustar la posición de la cámara según tus necesidades
                                }}
                            >
                                <SphereToPlaneWithRotation position={isMobile ? [0, 0, 0] : [0, 0, 0]} />
                            </Canvas>


                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column', // Para alinear verticalmente
                                    justifyContent: 'justify', // Para centrar horizontalmente
                                    textAlign: 'center', // Para centrar el texto
                                    marginTop: isMobile ? '-10vh' : '0',

                                }}
                            >

                                <br />

                                <p
                                    className="wow fadeInUp"
                                    data-wow-delay="0.1s"
                                    style={{ color: 'white', textAlign: 'justify' }} // Añadí color blanco al estilo y justificación al texto
                                >
                                    <span style={estilos}>
                                        {t('artDigitalDescription1')}
                                    </span>
                                </p>
                                <br />
                                <p
                                    className="wow fadeInUp"
                                    data-wow-delay="0.1s"
                                    style={{ color: 'white', textAlign: 'justify' }} // Añadí color blanco al estilo y justificación al texto
                                >
                                    <span style={estilos}>
                                        {t('artDigitalDescription2')}
                                    </span>
                                </p>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                            </div>



                            <div className="tf-section-2 featured-item style-bottom">
                                <div className="themesflat-container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="heading-section pb-20">
                                                <h2 className="tf-title " style={{ color: 'black' }} > Nuevas Colecciones</h2>
                                                <Link to="/explora-nft" className="white-link" style={{ color: 'black' }}  >Descubre Más <i className="icon-arrow-right2" /></Link>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <FeaturedSlider1 />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column', // Para alinear verticalmente
                                    justifyContent: 'center', // Para centrar horizontalmente
                                    textAlign: 'center', // Para centrar el texto
                                    marginTop: '-50px',

                                }}
                            >

                                <br />


                                <br />

                                <p
                                    className="wow fadeInUp"
                                    data-wow-delay="0.1s"
                                    style={{ color: 'white', textAlign: 'justify' }} // Añadí color blanco al estilo y justificación al texto
                                >
                                    <span style={estilos}>
                                        {t('nftDescription')}
                                    </span>
                                </p>

                                <br />
                                <p
                                    className="wow fadeInUp"
                                    data-wow-delay="0.1s"
                                    style={{ color: 'white', textAlign: 'justify' }} // Añadí color blanco al estilo y justificación al texto
                                >

                                    <span style={estilos}>

                                        {t('nftRevolutionText')}
                                    </span>
                                </p>
                            </div>



                        </div>
                    </div>

                </div>

            </Layout>
        </>
    )
}