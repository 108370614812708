import React from "react";
import { Months } from "./Moths";
import { Days } from "./Day";
import { DaysMonths } from "./DaysMonths";

// Este es el componente padre
class Calendario extends React.Component {
    constructor(props) {
        super(props);
        let newDate = new Date();
        let mesActual = newDate.getMonth();
        let yearActual = newDate.getFullYear(); // Añadimos el año actual
        this.state = { 
            mes: mesActual,
            year: yearActual // Añadimos el año al estado
        };

        this.changeMonth = this.changeMonth.bind(this);
    }

    // changeMonth lo que se hace es el cambio del mes y ponerlo como actual.
    changeMonth(dif) {
        let newMes = this.state.mes + dif;
        let newYear = this.state.year;

        if (newMes === 12) {
            newMes = 0;
            newYear += 1; // Cambiamos al siguiente año
        } else if (newMes === -1) {
            newMes = 11;
            newYear -= 1; // Cambiamos al año anterior
        }

        this.setState({ mes: newMes, year: newYear });
    }

    // En el render de padre se crean los arrays para los meses y otro para los días de la semana
    render() {
        var day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        var months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        // Fechas especiales (sesiones y semanas de estudio)
        var specialDates = [
            { date: 8, month: 10, year: 2024, event: "Sesión", hours: "2 horas" },   // 8 de noviembre 2024
            { date: 15, month: 10, year: 2024, event: "Sesión", hours: "2 horas" },  // 15 de noviembre 2024
            { date: 22, month: 10, year: 2024, event: "Sesión", hours: "2 horas" },  // 22 de noviembre 2024
            { date: 29, month: 10, year: 2024, event: "Sesión", hours: "2 horas" },  // 29 de noviembre 2024
            { date: 6, month: 11, year: 2024, event: "Sesión", hours: "2 horas" },   // 6 de diciembre 2024
            { date: 13, month: 11, year: 2024, event: "Sesión", hours: "2 horas" },  // 13 de diciembre 2024
            { date: 20, month: 11, year: 2024, event: "Sesión", hours: "2 horas" },  // 20 de diciembre 2024
            { date: 10, month: 0, year: 2025, event: "Sesión", hours: "2 horas" },   // 10 de enero 2025
            { date: 17, month: 0, year: 2025, event: "Sesión", hours: "2 horas" },   // 17 de enero 2025
            { date: 24, month: 0, year: 2025, event: "Sesión", hours: "2 horas" },   // 24 de enero 2025
            { date: 31, month: 0, year: 2025, event: "Sesión", hours: "2 horas" },   // 31 de enero 2025
            { date: 7, month: 1, year: 2025, event: "Sesión", hours: "2 horas" }     // 7 de febrero 2025
        ];

        // Filtra las fechas especiales del mes y año actual
        const filteredSpecialDates = specialDates.filter(date => 
            date.month === this.state.mes && date.year === this.state.year
        );

        // Se retornan lo que retornan los componentes hijos
        return (
            <div className="App roudBorder">
                <style>{`
   .App {
    font-family: sans-serif;
    text-align: center;
    width: 90%; /* Cambié a porcentaje */
    max-width: 900px; /* Puedes ajustar este valor según sea necesario */
    height: auto;
    margin: 50px auto;
    font-family: "Cabin", sans-serif;
}

    .roudBorder {
      border: 10px solid #004c4c;
      border-radius: 20px;
    }
    .calendario {
      background-color: #004c4c !important;
      color: white !important;
    }
  `}</style>
                <h1>Calendar {this.state.year}</h1> {/* Mostramos el año actual */}
                <Months
                    month={months[this.state.mes]}
                    numberMonth={this.state.mes}
                    onClick={this.changeMonth}
                />
                <Days dias={day} />
                <DaysMonths
                    dias={day}
                    mes={months[this.state.mes]}
                    numberMonth={this.state.mes}
                    specialDates={filteredSpecialDates} // Pasa las fechas especiales filtradas como prop
                />
            </div>
        );
    }
}

export default Calendario;
