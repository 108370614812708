import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'

import {
  MessageOutlined,
  CalendarOutlined,
  BankOutlined,
  CreditCardOutlined,
  GiftOutlined,
  RiseOutlined,
  DollarOutlined,
  WalletOutlined,
  BarChartOutlined,
  LineChartOutlined,
  ReadOutlined,
  RocketOutlined,
  CodeOutlined,
  ToolOutlined,
  SwapOutlined,
  GatewayOutlined,
  FireOutlined,
} from '@ant-design/icons'
import { RiArticleLine } from 'react-icons/ri'
import { ImBooks } from 'react-icons/im'
import Logo from '../reusable/LogoEU'
import Logo2 from '../reusable/Logo2'

const SideNav = (props) => {
  const { collapsed, onCollapse } = props
  const { Sider } = Layout
  const [openKeys, setOpenKeys] = useState([]) // Estado para controlar los submenús abiertos

  let currentPath = useLocation().pathname

  // dummy fix for side nav highlight
  if (currentPath.includes('/app/course/'))
    currentPath = currentPath.replace('/app/course/', '/app/courses/')

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (['crypto-tools', 'desarrollos'].includes(latestOpenKey)) {
      setOpenKeys([latestOpenKey]) // Solo permite abrir un submenú a la vez
    } else {
      setOpenKeys(keys)
    }
  }

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="lg"
      width={230}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        backgroundColor: '#1E5A67', // Verde turquesa
        left: 0
      }}
    >
      {!collapsed && (  // Condición para ocultar los logos cuando collapsed es true
        <div
          style={{
            display: 'flex',              // Flexbox para alineación en fila
            justifyContent: 'center',     // Centrar horizontalmente
            alignItems: 'center',         // Alinear verticalmente
            gap: '10px',                  // Espacio entre los logos
            padding: '10px',              // Añadir espacio alrededor si es necesario
            marginTop: '15%'              // Espacio adicional arriba
          }}
        >
          <div
            onClick={() => window.location.href = 'https://gp-eduhub.github.io/'}
            style={{
              display: 'flex',            // Flex para alinear el logo dentro
              justifyContent: 'center',
              alignItems: 'center',
              width: '100px',  // Ancho fijo ya que la condición es para mostrar solo cuando no está colapsado
              height: '80px',
            }}
          >
            <Logo width="70px" height="auto" />  {/* Ajusta el tamaño del logo */}
          </div>

          <div
            onClick={() => window.location.href = 'https://another-url.com'}
            style={{
              display: 'flex',            // Flex para alinear el logo dentro
              justifyContent: 'center',
              alignItems: 'center',
              width: '100px', // Ancho fijo ya que la condición es para mostrar solo cuando no está colapsado
              height: '80px',
            }}
          >
            <Logo2 width="70px" height="auto" />  {/* Ajusta el tamaño del logo */}
          </div>
        </div>
      )}

      <Menu
        theme="dark"
        mode="inline"
        activeKey={currentPath}
        selectedKeys={currentPath}
        openKeys={openKeys} // Vincula el estado del menú abierto
        onOpenChange={onOpenChange} // Maneja el cambio de estado cuando se abre/cierra un submenú
        style={{
          backgroundColor: '#1E5A67', // Verde turquesa
          overflow: 'auto',  // Permite que el menú tenga scroll si el contenido es demasiado largo
          height: 'calc(100vh - 50px)'    // Asegura que el menú ocupe el espacio disponible

        }}
      >
        {/* Secciones de la plataforma 
        <Menu.Item key="/app/dashboard" icon={<DashboardOutlined />}>
          <NavLink to="/app/dashboard">Dashboard</NavLink>
        </Menu.Item>*/}

        <Menu.Item key="/app/courses" icon={<ImBooks />}>
          <NavLink to="/app/courses">Courses</NavLink>
        </Menu.Item>
        <Menu.Item key="/app/calendar" icon={<CalendarOutlined />}>
          <NavLink to="/app/calender">Calendar</NavLink>
        </Menu.Item>
        <Menu.Item key="/app/crypto-calculator" icon={<RiArticleLine />}>
          <NavLink to="/app/crypto-calculator">Calculadora</NavLink>
        </Menu.Item>

        {/* Nueva sección: Herramientas de Criptomonedas */}
        <Menu.SubMenu key="Changes" icon={<RiseOutlined />} title="Changes 24h">
          <Menu.Item key="/app/chabge-crypto-btc" icon={<SwapOutlined />}>
            <NavLink to="/app/chabge-crypto-btc">Tracker Crypto/BTC</NavLink>
          </Menu.Item>

        </Menu.SubMenu>

        {/* Nueva sección: Herramientas de Criptomonedas */}
        <Menu.SubMenu key="desarrollos" icon={<CodeOutlined />} title="Desarrollos">
          <Menu.Item key="/app/BRC-20" icon={<BankOutlined />}>
            <NavLink to="/app/BRC-20">Token BRC-20</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/layer-2-blockchains" icon={<GatewayOutlined />}>
            <NavLink to="/app/layer-2-blockchains">Layer 2 Blockchains</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/meme-coin" icon={<FireOutlined />}>
            <NavLink to="/app/meme-coin">Lanzar Token Meme</NavLink>
          </Menu.Item>



        </Menu.SubMenu>

        {/* Nueva sección: Herramientas de Criptomonedas */}
        <Menu.SubMenu key="crypto-tools" icon={<ToolOutlined />} title="Crypto Tools">
          <Menu.Item key="/app/exchanges" icon={<DollarOutlined />}>
            <NavLink to="/app/exchanges">Exchanges</NavLink>
          </Menu.Item>

          <Menu.Item key="/app/wallets" icon={<WalletOutlined />}>
            <NavLink to="/app/wallets">Wallets</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/revistas" icon={<ReadOutlined />}>
            <NavLink to="/app/revistas">Revistas</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/margin-trading" icon={<RocketOutlined />}>
            <NavLink to="/app/margin-trading">Margin Trading</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/price-trackers" icon={<LineChartOutlined />}>
            <NavLink to="/app/price-trackers">Price Trackers</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/forum-btc" icon={<MessageOutlined />}>
            <NavLink to="/app/forum-btc">Forum</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/debito" icon={<CreditCardOutlined />}>
            <NavLink to="/app/debito">Cripto Débito</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/DEX-DeFi-Swap" icon={<SwapOutlined />}>
            <NavLink to="/app/DEX-DeFi-Swap">DEX/DeFi/Swap</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/airdrops" icon={<GiftOutlined />}>
            <NavLink to="/app/airdrops">Airdrops</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/IDO-ICO" icon={<RocketOutlined />}>
            <NavLink to="/app/IDO-ICO">IDO-ICO</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/market-analysis" icon={<BarChartOutlined />}>
            <NavLink to="/app/market-analysis">Market</NavLink>
          </Menu.Item>

        </Menu.SubMenu>
      </Menu>
    </Sider>
  )
}

export default SideNav
