import React from "react";

// Se crea el componente para los días del mes
export class DaysMonths extends React.Component {
  // Con firstDayInPreviousMonth se halla el primer día del mes
  firstDayInPreviousMonth() {
    var month = this.props.numberMonth;
    var year = "2024";
    var date = new Date(year, month, 1); // Ajustado para que acepte mes como índice
    return date.getUTCDay(); // Devuelve el día de la semana del primer día del mes
  }

  // Con getDaysMonths se obtiene los días que tiene el mes
  getDaysMonths() {
    var mes = this.props.numberMonth;
    return new Date(2024, mes + 1, 0).getDate(); // Último día del mes
  }

  // En get Tablero se obtiene un array con los días del mes
  get Tablero() {
    const nuevoArray = [];
    let cont = 0;
    let cont2 = 0;
    const firstDay = this.firstDayInPreviousMonth(); // Primer día de la semana en el mes
    const end = this.getDaysMonths(); // Último día del mes

    // Recorremos el array con unas condiciones para llenar la matriz del calendario
    for (let i = 0; i < 6; i++) { // Seis filas máximo
      nuevoArray[i] = [];
      for (let j = 0; j < 7; j++) { // Siete columnas para los días de la semana
        cont2++;
        if (cont2 > firstDay && cont < end) {
          cont++;
          nuevoArray[i][j] = cont;
        } else {
          nuevoArray[i][j] = " "; // Celdas vacías
        }
      }
    }

    return nuevoArray;
  }

  // Función para verificar si el día es una fecha especial (sesiones o semanas de estudio)
  isSpecialDate(day) {
    return this.props.specialDates.some(
      event => event.date === day && event.month === this.props.numberMonth
    );
  }

  // Renderizamos el tablero del calendario
  render() {
    const mes = this.props.numberMonth;
    const fecha = new Date();
    const mesActual = fecha.getMonth();
    const diaActual = fecha.getDate();
    const tablero = this.Tablero;

    const nuevoArray = tablero.map((fila, index) => {
      const listItems = fila.map((dia, i) => {
        let clases = i === 0 ? "Dom" : ""; // Domingo en rojo

        if (mesActual === mes && diaActual === dia) {
          clases = "Hoy"; // Día actual
        }

        if (this.isSpecialDate(dia)) {
          clases = "Especial"; // Día con sesión o semana de estudio
        }

        return <div key={i} className={clases}>{dia}</div>;
      });

      return <div key={index} className="DaysMonths">{listItems}</div>;
    });

    return (
      <div>
        <style>{`
          .App {
            font-family: sans-serif;
            text-align: center;
            width: 50%;
            margin: 50px auto;
            font-family: "Cabin", sans-serif;
          }
          .Days {
            display: flex;
            width: 100%;
            justify-content: space-around;
            text-align: center;
            align-items: center;
            box-sizing: border-box;
            font-weight: bolder;
            background-color: rgb(19, 197, 221);
          }
          .Days div {
            border: 1px solid rgb(16, 211, 211);
            width: 100%;
            height: 40px;
            align-items: center;
          }
          .Months {
            background-color: rgb(19, 197, 221);
            height: 50px;
            display: flex;
            width: 100%;
            border: 1px solid rgb(91, 165, 165);
            text-align: center;
            align-items: center;
            justify-content: space-around;
            box-sizing: border-box;
            font-weight: bolder;
          }
          .DaysMonths {
            display: flex;
            width: 100%;
            justify-content: space-around;
            text-align: center;
            box-sizing: border-box;
          }
          .DaysMonths div {
            border: 1px solid rgb(16, 211, 211);
            width: 100%;
            height: 40px;
            align-items: center;
            font-family: "Homer Simpson", cursive;
          }
          .Dom {
            color: red;
          }
          .Hoy {
            background-color: rgb(124, 136, 143);
          }
          .Especial {
            background-color: rgb(255, 223, 186); /* Color especial para los días de sesión */
          }
          @media screen and (max-width: 768px) {
            .App {
              width: 100%;
            }
          }
          .roudBorder {
            border: 10px solid rgb(19, 197, 221);
            border-radius: 20px;
          }
        `}</style>
        {nuevoArray}
      </div>
    );
  }
}
