
import { Autoplay, Navigation, Pagination } from "swiper/modules"
import { price } from "../../util/constants"
import CardSwiper from "../reusable/CardSwiper"
import { useState } from "react"
import BidModal from "../elements/BidModal"
import { useTranslation } from 'react-i18next';

const pexel = (id) =>
  `https://raw.githubusercontent.com/CoachblockchainDapp/TerraImages/main/${id}.png`;

const pexela = (id) =>
  `https://raw.githubusercontent.com/CoachblockchainDapp/AquaCollection/main/${id}.png`;


const nameathor = 'CoachBloackchain'
const aqua = 'https://raw.githubusercontent.com/CoachblockchainDapp/LogoNFTs/main/AQUA%20.png'



const swiperOptions = {
  modules: [Autoplay, Pagination, Navigation],
  loop: false,
  slidesPerView: 1,
  observer: true,
  observeParents: true,
  spaceBetween: 30,
  navigation: {
    clickable: true,
    nextEl: '.slider-next',
    prevEl: '.slider-prev'
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  autoplay: {
    delay: 2000,
    disableOnInteraction: false
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 30
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    1300: {
      slidesPerView: 4,
      spaceBetween: 30
    }
  }
}


const currentTime = new Date()

export default function FeaturedSlider1() {

  const { t } = useTranslation();
  const [isBidModal, setBidModal] = useState(false)
  const handleBidModal = () => setBidModal(!isBidModal)


  const slideData = [
    {
      image: pexela(110),
      productInfo: {
        name: "COLECCIÓN AQUA",
        price: price,
      },
      author: {
        avatar: aqua,
        link: "#",
        name: nameathor,
      },
    },
    {
      image: pexela(111),
      productInfo: {
        name: "COLECCIÓN AQUA",
        price: price,
      },
      author: {
        avatar: aqua,
        link: "#",
        name: nameathor,
      },
    },
    {
      image: pexela(112),
      productInfo: {
        name: "COLECCIÓN AQUA",
        price: price,
      },
      author: {
        avatar: aqua,
        link: "#",
        name: nameathor,
      },
    },
    {
      image: pexela(113),
      productInfo: {
        name: "COLECCIÓN AQUA",
        price: price,
      },
      author: {
        avatar: aqua,
        link: "#",
        name: nameathor,
      },
    },
    {
      image: pexela(114),
      productInfo: {
        name: "COLECCIÓN AQUA",
        price: price,
      },
      author: {
        avatar: aqua,
        link: "#",
        name: nameathor,
      },
    },
    {
      image: pexela(115),
      productInfo: {
        name: "COLECCIÓN AQUA",
        price: price,
      },
      author: {
        avatar: aqua,
        link: "#",
        name: nameathor,
      },
    },
    {
      image: pexela(117),
      productInfo: {
        name: "COLECCIÓN AQUA",
        price: price,
      },
      author: {
        avatar: aqua,
        link: "#",
        name: nameathor,
      },
    },
    {
      image: pexela(118),
      productInfo: {
        name: "COLECCIÓN AQUA",
        price: price,
      },
      author: {
        avatar: aqua,
        link: "#",
        name: nameathor,
      },
    },
    {
      image: pexela(119),
      productInfo: {
        name: "COLECCIÓN AQUA",
        price: price,
      },
      author: {
        avatar: aqua,
        link: "#",
        name: nameathor,
      },
    },
    {
      image: pexela(120),
      productInfo: {
        name: "COLECCIÓN AQUA",
        price: price,
      },
      author: {
        avatar: aqua,
        link: "#",
        name: nameathor,
      },
    },
    // ... agregar más elementos según sea necesario
  ]




  return (
    <>
      {/* <Swiper {...swiperOptions} className="abc">
                <SwiperSlide>Slide 1</SwiperSlide>
            </Swiper> */}

      <div className="featured pt-10 swiper-container carousel">
        <CardSwiper slideData={slideData} handleBidModal={handleBidModal} currentTime={currentTime} swiperOptions={swiperOptions} />
        <div className="swiper-pagination" />
        <div className="slider-next swiper-button-next" />
        <div className="slider-prev swiper-button-prev" />
      </div>
      <BidModal handleBidModal={handleBidModal} isBidModal={isBidModal} />
    </>
  )
}
