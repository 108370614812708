import React from 'react';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';

const LogoCard = styled(Card)`
  width: ${(props) => props.width || '300px'}; /* Ajusta el ancho con props */
  height: ${(props) => props.height || '150px'}; /* Ajusta la altura con props */
  overflow: hidden; /* Asegura que el contenido que excede el tamaño se oculte */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; /* Fondo transparente */
  box-shadow: none; /* Elimina el sombreado */
  border: none; /* Quita el borde */
`;

const LogoImage = styled.img`
  width: 80%;
  height: auto;
  object-fit: cover; /* Ajusta la imagen para que se recorte adecuadamente */
`;

 const Logo = ({ width, height }) => {
  const imageUrl =
    'https://firebasestorage.googleapis.com/v0/b/coachblokchain.appspot.com/o/Escudo-IE.png?alt=media&token=da4406b5-7258-4a0e-a499-f3376b5792b7';

  return (
    <LogoCard width={width} height={height}>
      <LogoImage src={imageUrl} alt="Logo" />
    </LogoCard>
  );
};

export default Logo;
