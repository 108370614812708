import React, { useState, useEffect } from 'react';
import { ReusableButton, NFTGallery } from "../../components";
import { cardStyle } from "../../components/reusable/styles"
import { useMediaQuery } from 'react-responsive';
import Layout from '../../components/layout/Layout'


const pexel = (id) =>
  `https://raw.githubusercontent.com/coachblockchain/Images/main/${id}.png`;



const images = [
  // Front
  { position: [0, 0, 1.5], rotation: [0, 0, 0], url: pexel(4048) },
  // Back
  { position: [-0.8, 0, -0.8], rotation: [0, 0, 0], url: pexel(10134) },
  { position: [0.8, 0, -0.8], rotation: [0, 0, 0], url: pexel(90) },
  // Left
  {
    position: [-2, 0, -0.1],
    rotation: [0, Math.PI / 2.5, 0],
    url: pexel(4003),
  },
  {
    position: [-2.15, 0, 1.5],
    rotation: [0, Math.PI / 2.5, 0],
    url: pexel(48),
  },
  {
    position: [-2, 0, 2.8],
    rotation: [0, Math.PI / 2.5, 0],
    url: pexel(10331),
  },
  // Right
  {
    position: [2, 0, -0.1],
    rotation: [0, -Math.PI / 2.5, 0],
    url: pexel(10097),
  },
  {
    position: [2.15, 0, 1.5],
    rotation: [0, -Math.PI / 2.5, 0],
    url: pexel(4196),
  },
  {
    position: [2, 0, 2.8],
    rotation: [0, -Math.PI / 2.5, 0],
    url: pexel(10215),
  },
];



export const Home = () => {


  const [esPantallaPequena, setEsPantallaPequena] = useState(window.innerWidth < 600);
  const isMobile = useMediaQuery({ maxWidth: 768 });


  useEffect(() => {
    function manejarCambioDeTamano() {
      setEsPantallaPequena(window.innerWidth < 600);
    }

    window.addEventListener('resize', manejarCambioDeTamano);
    return () => window.removeEventListener('resize', manejarCambioDeTamano);
  }, []);





  // Estilos dinámicos
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const dynamicCanvasContainerStyle = {
    width: isMobile ? '100%' : '70vw',
    height: isMobile ? '300px' : '90vh',
    marginTop: isMobile ? '22vh' : '-40vh',
    zIndex: isMobile ? 'auto' : '0', // Asigna el valor deseado para PC
  };

  const dynamicTextContainerStyle = {
    textAlign: 'center',
    fontSize: isMobile ? '14px' : '30px',
    width: isMobile ? '88vw' : '70vw',
    height: isMobile ? '20vh' : '55vh',
    color: 'black',
    lineHeight: '1.5',
    marginBottom: isMobile ? '50px' : 0,
    marginTop: isMobile ? '50px' : '0',
    zIndex: isMobile ? 'auto' : '1', // Asigna el valor deseado para PC
  };

  const h1Style = {
    fontFamily: 'Univers, sans-serif',
    fontSize: '40px',
    margin: 0,
    textShadow: '2px 2px 2px #94939A',
    lineHeight: '1.5',
  };

  const h3Style = {
    fontFamily: 'Univers, sans-serif',
    fontSize: '23px',
    margin: 0,
    textShadow: '1px 1px 1px #94939A',
    lineHeight: '0.8',
    fontWeight: 'normal', // Agrega esta línea para quitar la negrita
    height: '20%',

  };

  const buttonContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    marginTop: isMobile ? '20px' : '-10vw',
  };



  return (


    <Layout headerStyle={1} footerStyle={1} pageCls="about-us-page" >
      <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
        <div style={cardStyle}>
          <div style={containerStyle}>
            <div style={dynamicTextContainerStyle}>

              <h1 style={h1Style}>
                El valor de la Tecnología Blockchain 
              </h1>
              <br></br>
              <h3 style={h3Style}>
              Conoce la Tecnología Blockchain y el Arte Digital formándote con los mejores profesionales.
              </h3>
            </div>
            <div style={dynamicCanvasContainerStyle}>
              <NFTGallery images={images} />
            </div>
          </div>
          <div style={buttonContainerStyle}>
            <ReusableButton
              backgroundColor="linear-gradient(0deg, rgba(10,83,100,1) 0%, rgba(10,83,100,1) 100%)"
              hoverColor="linear-gradient(315deg, rgba(30,83,105,1) 0%, rgba(30,83,105,1) 100%)"
              textColor="#fff"
              text="Empezar"
              linkTo="/registro"
            />
            <ReusableButton
              backgroundColor="linear-gradient(0deg, rgba(10,83,100,1) 0%, rgba(10,83,100,1) 100%)"
              hoverColor="linear-gradient(315deg, rgba(30,83,105,1) 0%, rgba(30,83,105,1) 100%)"
              textColor="#fff"
              text="Descubre"
              linkTo="/explora-nft"
            />


          </div>
        </div>
      </div>

    </Layout>
  );
};



