import React, { useState, useEffect } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { Navbar, Nav, Button, Container, Alert } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { ethers } from 'ethers';
import { Logo, WidthSpacer } from '../../components';
import Logo_ from '../reusable/Logo2'
import LogoEU from '../reusable/LogoEU'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';




// Estilos para la barra de navegació
const StyledNavbar = styled(Navbar)`
    background-color: #0A5364;
    height: 12vh;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    margin-top: -40px;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;



// Estilos para los enlaces de navegación activos
const StyledNavLink = styled(NavLink)`
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
    font-size: 18px;
    font-weight: bold;

    &:hover {
        color: #D4AE42 !important;
    }

    &.active {
        background-color: transparent !important;
        color: #D4AE42 !important;
    }
`;

const StyledButton = styled(Button)`
   && {
      border: 2px solid #fff;
      border-radius: 5px;
      color: #fff;
      font-size: 12px; /* Reducir el tamaño de la fuente */
      height: 6vh; /* Ajustar la altura para encajar mejor */
      padding: 5px 10px; /* Ajustar el padding */
      margin-bottom: 0;
      background-color: transparent;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   &:hover, &:focus {
      background-color: #D4AE42;
      color: #000 !important;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
   }

   @media (max-width: 768px) {
      && {
         height: 3vh; /* Ajustar para pantallas pequeñas */
         width: 40vw;
         padding: 5px 10px;
         margin-bottom: 10px;
         border: none;
         background-color: #D4AE42;
         color: #000;
      }
   }
`;


const MobileSpacing = styled.div`
    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

const StyledNavColumn = styled(Nav)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Permite que los elementos se envuelvan si es necesario */
    align-items: center;
`;



// Estilo para la Card (tarjeta) que aparecerá en la parte superior
const AlertCard = styled(Card)`
   position: fixed;
   top: 10px;
   left: 50%;
   transform: translateX(-50%);
   z-index: 9999;
   width: 350px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;






// Incluyendo contrato
const contractAddress = require(`../../contractsData/${'DataStorage'}-address.json`).address;
const contractABI = require(`../../contractsData/${'DataStorege-data'}.json`);

export const Navigation = ({ web3Handler, account }) => {
    const { t } = useTranslation();
    const [isRegister, setIsRegister] = useState(false);
    const [userAddress, setUserAddress] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();

    const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : null;
    const contract = new ethers.Contract(contractAddress, contractABI, provider);

    useEffect(() => {
        const init = async () => {
            if (window.ethereum) {
                try {
                    const accounts = await provider.send('eth_requestAccounts', []);
                    if (accounts.length > 0) {
                        const userAddress = accounts[0];
                        setUserAddress(userAddress);
                        loadIsRegister(userAddress);
                    }
                } catch (error) {
                    console.error('Error al obtener la dirección del usuario:', error);
                }

                const handleAccountsChanged = (accounts) => {
                    if (accounts.length > 0) {
                        const userAddress = accounts[0];
                        setUserAddress(userAddress);
                        loadIsRegister(userAddress);
                    } else {
                        setIsRegister(false);
                    }
                };

                window.ethereum.on('accountsChanged', handleAccountsChanged);

                return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            }
        };

        init();
    }, []);

    async function loadIsRegister(address) {
        if (!window.ethereum) {
            console.log('Ethereum object not found, install MetaMask.');
            return;
        }
        try {
            const _isRegister = await contract.isUserRegistered(address);
            setIsRegister(_isRegister);
        } catch (error) {
            console.error('Error al obtener detalles de registro del usuario', error);
        }
    }

    const handleRegistroClick = () => {
        navigate('/registro');
    };

    /*const handleLoginClick = () => {
        // Simular que el usuario no está registrado
        const isRegistered = false;

        if (!isRegistered) {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000); // Ocultar la alerta después de 5 segundos
        } else {
            console.log("Usuario registrado, conectando wallet...");
        }
    };*/

    const handleLoginClick = () => {
        if (isRegister) {
          navigate('/login');
        } else {
          setAlertMessage('Usuario no registrado');
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 2000);
          alert('El usuario ' + userAddress + ' no está registrado');
        }
      };

    return (
        <>

            {showAlert && (
                <AlertCard>
                    <Card.Body>
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            <Alert.Heading>¡Atención!</Alert.Heading>
                            <p>Usuario no registrado o wallet no registrada en MetaMask.</p>
                        </Alert>
                    </Card.Body>
                </AlertCard>
            )}

            <StyledNavbar expand="lg" variant="dark" className="fixed-top" style={{ top: '5vh' }}>








                <Navbar.Toggle aria-controls="navbar navbar-dark bg-primary" />
                  <Navbar.Collapse id="navbar navbar-dark bg-primary">
               
                    <Navbar.Brand>
                        <Logo width="120px" height="12vh" mageUrl="https://raw.githubusercontent.com/CoachblockchainDapp/coachblokchain/main/IMAGOTIPO%20RECTANGULAR%20OCRE%20SOLIDO_Mesa%20de%20trabajo%201.png" />

                    </Navbar.Brand>
                    <Spacer />
                    <Nav className="me-auto">
                        <MobileSpacing>
                            <StyledNavLink as={Link} to="/inicio" activeClassName="active">
                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{t('inicio')}</span>
                            </StyledNavLink>
                        </MobileSpacing>
                        <MobileSpacing>
                            <StyledNavLink as={Link} to="/detalles" activeClassName="active">
                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>NFTs</span>
                            </StyledNavLink>
                        </MobileSpacing>
                        <MobileSpacing>
                            <StyledNavLink as={Link} to="/formacion" activeClassName="active">
                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{t('formacion')}</span>
                            </StyledNavLink>
                        </MobileSpacing>
                        <MobileSpacing>
                            <StyledNavLink as={Link} to="/guia-uso" activeClassName="active">
                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>Guía</span>
                            </StyledNavLink>
                        </MobileSpacing>
                    </Nav>
                   
                    <WidthSpacer width="100px" /> 
                   
                    <StyledNavColumn>
                        {account ? (
                            <Nav.Link
                                href={`https://etherscan.io/address/${account}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="button nav-button btn-sm mx-4"
                            >
                                <StyledButton variant="outline-light">{account}</StyledButton>
                            </Nav.Link>
                        ) : (
                            <>
                                <StyledButton onClick={handleRegistroClick} variant="outline-light">
                                    {t('registro')}
                                </StyledButton>
                                <WidthSpacer width="10px" />
                                <StyledButton onClick={handleLoginClick} variant="outline-light">
                                    {t('conectarWallet')}
                                </StyledButton>
                            </>
                        )}

                        <Spacer />
                        <WidthSpacer width="100px" /> {/* Espacio adicional para mayor separación */}
                        <Navbar.Brand>
                       
                  
                       <LogoEU width="70px" height="8vh" marginBottom="10px" />

                   </Navbar.Brand>
                           <Navbar.Brand>
                       
                        <Logo_ width="70px" height="8vh" marginBottom="10px" />
                      

                    </Navbar.Brand>
                 
                    </StyledNavColumn>
                </Navbar.Collapse>

            </StyledNavbar>
        </>
    );
};
