import React, { useState, useEffect } from 'react';

const CryptoTicker = () => {
  const [cryptoData, setCryptoData] = useState([]);

  useEffect(() => {
    const fetchCryptoData = async () => {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=20&page=1&sparkline=false'
        );
        const data = await response.json();
        setCryptoData(data);
      } catch (error) {
        console.error('Error fetching crypto data:', error);
      }
    };

    fetchCryptoData();
    const interval = setInterval(fetchCryptoData, 30000); // Actualizar cada 30 segundos

    return () => clearInterval(interval);
  }, []);

  const tickerStyles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff', // Fondo blanco
      color: '#000', // Texto negro
      padding: '5px', // Reducir el padding
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    logoContainer: {
      marginRight: '10px', // Reducir margen
    },
    logoImage: {
      height: '30px', // Reducir tamaño de logo
    },
    tickerWrap: {
      display: 'inline-block',
      width: '100%',
      overflow: 'hidden',
    },
    ticker: {
      display: 'inline-block',
      paddingLeft: '100%',
      animation: 'ticker 60s linear infinite', // Animación más lenta
    },
    tickerItem: {
      display: 'inline-block',
      padding: '0 10px', // Reducir espacio entre elementos
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    coinLogo: {
      height: '20px', // Reducir tamaño de logo de monedas
      marginRight: '5px',
      verticalAlign: 'middle',
    },
    coinName: {
      fontWeight: 'bold',
      marginRight: '3px',
    },
    coinSymbol: {
      color: '#666', // Cambiar a gris oscuro
      marginRight: '3px',
    },
    coinPrice: {
      marginRight: '5px',
    },
    priceUp: {
      color: 'green',
    },
    priceDown: {
      color: 'red',
    },
  };

  const keyframesStyle = `
    @keyframes ticker {
      0% { transform: translateX(100%); }
      100% { transform: translateX(-100%); }
    }
  `;

  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(keyframesStyle, styleSheet.cssRules.length);

  return (
    <div style={tickerStyles.container}>
      <div style={tickerStyles.logoContainer}>
        <img src="https://raw.githubusercontent.com/CoachblockchainDapp/coachblokchain/main/IMAGOTIPO%20RECTANGULAR%20OCRE%20SOLIDO_Mesa%20de%20trabajo%201.png" alt="Logo" style={tickerStyles.logoImage} />
      </div>
      <div style={tickerStyles.tickerWrap}>
        <div style={tickerStyles.ticker}>
          {cryptoData.slice(0, 20).map((coin) => ( // Cambiar a 20 criptomonedas
            <div key={coin.id} style={tickerStyles.tickerItem}>
              <img src={coin.image} alt={coin.name} style={tickerStyles.coinLogo} />
              <span style={tickerStyles.coinName}>{coin.name}</span>
              <span style={tickerStyles.coinSymbol}>({coin.symbol.toUpperCase()})</span>
              <span style={tickerStyles.coinPrice}>${coin.current_price.toFixed(2)}</span>
              <span style={coin.price_change_percentage_24h > 0 ? tickerStyles.priceUp : tickerStyles.priceDown}>
                {coin.price_change_percentage_24h > 0 ? `+${coin.price_change_percentage_24h.toFixed(2)}%` : `${coin.price_change_percentage_24h.toFixed(2)}%`}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CryptoTicker;
