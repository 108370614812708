import { Link } from "react-router-dom";
import '../../../public/assets/css/style.css';



export default function Footer1() {




    return (
        <>
            <footer id="footer" >
                <div className="themesflat-container"  >
                    <div className="row"  >
                        <div className="col-12">


                            <div className="footer-content flex flex-grow">

                                <div className="widget-logo" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <div className="logo-footer" id="logo-footer-1" style={{ backgroundColor: '#105563', width: '95%', padding: '10px', borderRadius: '15px', marginBottom: '20px' }}>
                                        <Link to="/">
                                            <img
                                                id="logo_footer_1"
                                                src="https://raw.githubusercontent.com/coachblockchain/Images/main/COACH%20ORO.png"
                                                alt="Logo de Coach Blockchain"
                                                style={{ maxWidth: '100%', height: 'auto' }}
                                            />
                                        </Link>
                                    </div>
                                    <div className="logo-footer" id="logo-footer-2" style={{ width: '70%', padding: '2px' }}>
                                        <div className="logo-footer" id="logo-footer-2" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px' }}>
                                            <Link to="/" style={{ flex: '1', paddingRight: '5px' }}>
                                                <img
                                                    id="logo_footer_1"
                                                    src="https://firebasestorage.googleapis.com/v0/b/coachblokchain.appspot.com/o/Escudo-IE.png?alt=media&token=da4406b5-7258-4a0e-a499-f3376b5792b7"
                                                    alt="Logo UVEG"
                                                    style={{ width: '100%', height: 'auto' }}
                                                />
                                            </Link>
                                            <Link to="/" style={{ flex: '1', paddingLeft: '5px' }}>
                                                <img
                                                    id="logo_footer_2"
                                                    src="https://firebasestorage.googleapis.com/v0/b/coachblokchain.appspot.com/o/logo-blanco%202.png?alt=media&token=566d4557-1aa4-41d9-8907-7b67ee6c75f9"
                                                    alt="Logo UVEG"
                                                    style={{ width: '100%', height: 'auto' }}
                                                />
                                            </Link>
                                        </div>

                                    </div>
                                </div>



                                <div className="widget-row">
                                    <div className="widget-col">
                                        <div className="widget widget-menu style-1"  >
                                            <h5 className="title-widget" >Mercado</h5>
                                            <ul>
                                                <li><Link to="/explora-nft">Todos los NFT</Link></li>
                                                <li><Link to="/detalles">Detalles</Link></li>
                                                <li><Link to="/explora-nft">Nuestros NFT</Link></li>
                                                <li><Link to="/detalles">Arte Digital</Link></li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div className="widget-col">
                                        <div className="widget widget-menu style-2">
                                            <h5 className="title-widget">Recursos</h5>
                                            <ul>
                                     
                                        
                                                <li><Link to="/formacion">Conócenos</Link></li>
                                                <li><Link to="/comunidad">Descubre</Link></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>



                                <div className="widget-row">
                                    <div className="widget-col">
                                        <div className="widget widget-menu style-3">
                                            <h5 className="title-widget">Cuenta</h5>
                                            <ul>
                                                <li><Link to="/explora-nft">Equipo</Link></li>
                                                <li><Link to="/explora-nft">Colecciones</Link></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="widget-col">
                                        <div className="widget-last">
                                            <div className="widget-menu style-4">
                                                <h5 className="title-widget">Marketplace</h5>
                                                <ul>
                                                    <li><Link to="/guia-uso">Centro de ayuda</Link></li>
                                                </ul>
                                            </div>
                                            <h5 className="title-widget mt-3" style={{ textAlign: 'center' }}>¡Únete a la comunidad!</h5>

                                        </div>
                                    </div>

                                </div>
                            </div>




                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p>© {new Date().getFullYear()} COACH BLOCKCHAIN</p>
                        <ul className="flex">
                            <li>
                                <Link to="/terms-condictions">Pilítica de privacidad</Link>
                            </li>
                            <li>
                                <Link to="/terminos-servicio">Términos de servicio</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </footer>

        </>
    )
}
