import React from 'react';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';

const LogoCard = styled(Card)`
  width: ${(props) => props.width || '300px'}; /* Ajusta el ancho con props */
  height: ${(props) => props.height || '150px'}; /* Ajusta la altura con props */
  overflow: hidden; /* Asegura que el contenido que excede el tamaño se oculte */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; /* Fondo transparente */
  box-shadow: none; /* Elimina el sombreado */
  border: none; /* Quita el borde */
  backdrop-filter: none; /* Asegúrate de que no haya efectos de desenfoque */
`;

const LogoImage = styled.img`
  width: 80%;
  height: auto;
  object-fit: cover; /* Ajusta la imagen para que se recorte adecuadamente */
`;

export const Logo = ({ width, height }) => {
  const imageUrl =
    'https://raw.githubusercontent.com/CoachblockchainDapp/coachblokchain/main/IMAGOTIPO%20RECTANGULAR%20OCRE%20SOLIDO_Mesa%20de%20trabajo%201.png';

  return (
    <LogoCard width={width} height={height}>
      <LogoImage src={imageUrl} alt="Logo" />
    </LogoCard>
  );
};
