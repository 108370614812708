import React from "react";

//En este componente se trae el array de los dias de la semana del componente padre.
export class Days extends React.Component {
  render() {
    var day = this.props.dias;

    return (
      <div className="Days">
        <div>{day[0]}</div>
        <div>{day[1]}</div>
        <div>{day[2]}</div>
        <div>{day[3]}</div>
        <div>{day[4]}</div>
        <div>{day[5]}</div>
        <div>{day[6]}</div>
      </div>
    );
  }
}
