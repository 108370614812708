import React, { useState, useEffect } from "react";
import axios from "axios";

const CryptoConverter = () => {
  const [cryptoList, setCryptoList] = useState([]);
  const [fiatList, setFiatList] = useState([]);
  const [fromCurrency, setFromCurrency] = useState("bitcoin"); // Moneda de origen
  const [toCurrency, setToCurrency] = useState("usd"); // Moneda de destino
  const [amount, setAmount] = useState(1);
  const [convertedAmount, setConvertedAmount] = useState(0);

  useEffect(() => {
    // Fetch available cryptocurrencies
    axios
      .get("https://api.coingecko.com/api/v3/coins/list")
      .then((response) => {
        setCryptoList(response.data);
      })
      .catch((error) => console.error(error));

    // Hardcode available fiat currencies (you can replace this with an API call if needed)
    setFiatList(["usd", "eur", "gbp", "jpy", "inr"]);
  }, []);

  useEffect(() => {
    // Fetch the conversion rate between the selected currencies
    if (fromCurrency && toCurrency) {
      axios
        .get(
          `https://api.coingecko.com/api/v3/simple/price?ids=${fromCurrency}&vs_currencies=${toCurrency}`
        )
        .then((response) => {
          const rate = response.data[fromCurrency][toCurrency];
          setConvertedAmount(amount * rate);
        })
        .catch((error) => console.error(error));
    }
  }, [fromCurrency, toCurrency, amount]);

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Crypto Converter</h4>
          <form>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="fromCurrency" className="form-label">
                  From (Cryptocurrency or Fiat)
                </label>
                <select
                  id="fromCurrency"
                  className="form-select"
                  value={fromCurrency}
                  onChange={(e) => setFromCurrency(e.target.value)}
                >
                  <optgroup label="Cryptocurrencies">
                    {cryptoList.map((coin) => (
                      <option key={coin.id} value={coin.id}>
                        {coin.name}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Fiat Currencies">
                    {fiatList.map((currency) => (
                      <option key={currency} value={currency}>
                        {currency.toUpperCase()}
                      </option>
                    ))}
                  </optgroup>
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="toCurrency" className="form-label">
                  To (Cryptocurrency or Fiat)
                </label>
                <select
                  id="toCurrency"
                  className="form-select"
                  value={toCurrency}
                  onChange={(e) => setToCurrency(e.target.value)}
                >
                  <optgroup label="Cryptocurrencies">
                    {cryptoList.map((coin) => (
                      <option key={coin.id} value={coin.id}>
                        {coin.name}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Fiat Currencies">
                    {fiatList.map((currency) => (
                      <option key={currency} value={currency}>
                        {currency.toUpperCase()}
                      </option>
                    ))}
                  </optgroup>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="amount" className="form-label">
                  Amount
                </label>
                <input
                  type="number"
                  id="amount"
                  className="form-control"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5>
                  {amount} {fromCurrency} = {convertedAmount} {toCurrency.toUpperCase()}
                </h5>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CryptoConverter;
