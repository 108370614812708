import { useEffect, useState } from "react";
import BackToTop from '../elements/BackToTop';
import Breadcrumb from './Breadcrumb';
import PageHead from './PageHead';
import Footer1 from './footer/Footer1';
import Footer2 from './footer/Footer2';

export default function Layout({ footerStyle, headTitle, breadcrumbTitle, children, pageCls }) {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const WOW = require('wowjs');
        window.wow = new WOW.WOW({
            live: false
        });
        window.wow.init();

        const onScroll = () => {
            const scrollCheck = window.scrollY > 100;
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck);
            }
        };

        document.addEventListener("scroll", onScroll);

        return () => document.removeEventListener("scroll", onScroll);
    }, [scroll]);

    return (
        <>
            <PageHead headTitle={headTitle} />
            <div id="wrapper" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                {/* Elimina el scroll interno, permite que el contenido crezca naturalmente */}
                <div id="page" className={`pt-40 ${pageCls ? pageCls : ""}`} 
                    style={{ 
                        flexGrow: 1, 
                        paddingBottom: '50px', /* Deja espacio para el footer */
                    }}>
                    {breadcrumbTitle && <Breadcrumb breadcrumbTitle={breadcrumbTitle} />}
                    {children}
                </div>
                {/* Aseguramos que el footer esté al final del contenido */}
                <footer style={{ width: "100%", padding: "20px", backgroundColor: '#f1f1f1', textAlign: 'center', marginTop: 'auto' }}>
                    {!footerStyle && <Footer1 />}
                    {footerStyle === 1 ? <Footer1 /> : null}
                    {footerStyle === 2 ? <Footer2 /> : null}
                </footer>
            </div>
            <BackToTop />
        </>
    );
}
