import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { ethers } from 'ethers';

import {
  Navigation,
  Home,
  About,
  NFTDetails,
} from '../components';

import FAQWithHours from '../screens/traning/FAQWithHours'
import Iframe from '../screens/courses/finanzas/Iframe'
import enlaces from '../screens/courses/components/enlaces'
import Caleder from '../screens/calender/index'

import SideNav from './SideNav';
import CoinMarqueeHeader from './HeaderLogin/CoinMarqueeHeader'
import Dashboard from '../screens/views/dashboard';
import CourseCalendar from '../screens/views/courseCalendar';
import RegistrationCard from '../screens/registration/RegistrationCard';
import HomeAbout from '../screens/about/about-us';
import Explora from '../pages/explore-1';
import Detalles from '../pages/product-detail-1';
import Contact from '../pages/contact-us';
import TermsCondictions from '../pages/terms-condition';
import UseGuia from '../screens/guia/UseGuia';
import Servicio from '../screens/politicas/TerminosServicio';
import Login from '../screens/authentication/Login';
import AllUsers from '../components/featured/allUsers';
import Certificate from '../screens/certificate/Certificate';
import ScrollToTop from './elements/ScrollToTop';
import Formaciones from '../pages/blog-grid';

import MarketplaceAbi from '../contractsData/Marketplace.json';
import MarketplaceAddress from '../contractsData/Marketplace-address.json';
import NFTAbi from '../contractsData/NFT.json';
import NFTAddress from '../contractsData/NFT-address.json';


import BestCryptoTools from '../screens/cryptotools/CryptoTools'
import CoinConverter from '../screens/cryptotools/CoinConverter'
import Changes24 from '../screens/cryptotools/change24'
import CryptoCalculator from '../screens/cryptotools/CryptoCalculator'

import exchangeData from './Data/exchange.json'
import exchangeList from './Data/exchanges_list.json'
import news from './Data/news.json'
import newslist from './Data/newslist.json'
import wallets from './Data/wallets.json'
import walletslist from './Data/walletList.json'
import tradingBTC from './Data/TradingBTC.json'
import tradingBTClist from './Data/TradingBTClist.json'
import MemeCoin from './Data/TokenMeme.json'
import MemeCoinList from './Data/TokenMemelist.json'
import cryptoPrice from './Data/cyptoprices.json'
import ctyprolistprice from './Data/cryptopricelist.json'
import forumbtc from './Data/blogsBTC.json'
import forumbtclist from './Data/blogsBTClist.json'
import debito from './Data/Debito.json'
import debitolist from './Data/Debitolist.json'
import dex1 from './Data/DEX-DeFi-Swap.json'
import dex1list from './Data/DEXList.json'
import airdrops from './Data/Airdrops.json'
import aidropslist from './Data/Airdopslist.json'
import IDOdata from './Data/IDO.json'
import IDOList from './Data/IDOlist.json'
import BRC20data from './Data/BRC-20.json'
import BRC20list from './Data/BRC-20List.json'
import layer2 from './Data/Layer2blockchains.json'
import layer2list from './Data/Layer2blockchainList.json'
import revistas from './Data/revistas.json'
import revistasList from './Data/revistasLists.json'



import './App.css';


function App() {
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [nft, setNFT] = useState({});
  const [marketplace, setMarketplace] = useState({});
  const [collapsed, setCollapsed] = useState(false);

  const web3Handler = async () => {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    setAccount(accounts[0]);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    window.ethereum.on('chainChanged', () => {
      window.location.reload();
    });

    window.ethereum.on('accountsChanged', async function (accounts) {
      setAccount(accounts[0]);
      await web3Handler();
    });

    loadContracts(signer);
  };

  const loadContracts = async (signer) => {
    const marketplace = new ethers.Contract(MarketplaceAddress.address, MarketplaceAbi.abi, signer);
    setMarketplace(marketplace);
    const nft = new ethers.Contract(NFTAddress.address, NFTAbi.abi, signer);
    setNFT(nft);
    setLoading(false);
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  useEffect(() => {
    if (account) {
      loadContracts();
    }
  }, [account]);

  return (
    <BrowserRouter>
      <div className="App">
        <ScrollToTop />

        {loading ? (
          <Navigation web3Handler={web3Handler} account={account} />
        ) : (
          <>
            <div>
              <div
                style={{
                  position: 'fixed', // Fijar la posición
                  top: '-5px', // Alineado al inicio de la página
                  left: 0, // Alineado al borde izquierdo
                  width: '100%', // 100% del ancho de la página
                  zIndex: 1000, // Asegurar que se quede en la parte superior de otros elementos
                  background: '#fff', // Fondo blanco
                  padding: '0 24px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra debajo del div
                }}
              >
                <CoinMarqueeHeader />
              </div>
              <SideNav collapsed={collapsed} onCollapse={onCollapse} />
            </div>

          </>
        )}

        <div>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '80vh',
                background: 'white',
              }}
            >
              <Routes>
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route path="/" element={<Home marketplace={marketplace} nft={nft} />} />
                <Route path="/formacion" element={
                  < FAQWithHours />
                } />
                <Route path="/conocenos" element={<About marketplace={marketplace} nft={nft} />} />
                <Route path="/comunidad" element={<HomeAbout />} />
                <Route path="/explora-nft" element={<Explora />} />
                <Route path="/contactanos" element={<Contact />} />
                <Route path="/detalles" element={<Detalles />} />
                <Route path="/terms-condictions" element={<TermsCondictions marketplace={marketplace} nft={nft} />} />
                <Route path="/registro" element={<RegistrationCard web3Handler={web3Handler} account={account} />} />
                <Route path="/guia-uso" element={<UseGuia />} />
                <Route path="/login" element={<Login web3Handler={web3Handler} account={account} />} />
                <Route path="/terminos-servicio" element={<Servicio />} />
              </Routes>
            </div>
          ) : (
            <Routes>

              <Route path="/app/dashboard" element={<Dashboard />} />
              <Route path="/app/calender" element={<Caleder />} />
              <Route path="/app/calendar" element={<CourseCalendar />} />
              <Route path="/app/courses" element={<Formaciones />} />
              <Route path="/nft-datelles" element={<NFTDetails marketplace={marketplace} nft={nft} />} />
              <Route path="/explorar" element={<Explora />} />
              <Route path="/all-users" element={<AllUsers />} />
              <Route path="/certificate" element={<Certificate />} />


              <Route path="/app/chabge-crypto-btc" element={<Changes24 />} />

              <Route path="/app/crypto-calculator" element={<CryptoCalculator />} />
              <Route path="/coin-converter" element={<CoinConverter />} />
              <Route path="/app/exchanges" element={<BestCryptoTools data1={exchangeData} data2={exchangeList} />} />
              <Route path="/app/news" element={<BestCryptoTools data1={news} data2={newslist} />} />
              <Route path="/app/wallets" element={<BestCryptoTools data1={wallets} data2={walletslist} />} />
              <Route path="/app/margin-trading" element={<BestCryptoTools data1={tradingBTC} data2={tradingBTClist} />} />
              <Route path="/app/meme-coin" element={<BestCryptoTools data1={MemeCoin} data2={MemeCoinList} />} />
              <Route path="/app/price-trackers" element={<BestCryptoTools data1={cryptoPrice} data2={ctyprolistprice} />} />
              <Route path="/app/forum-btc" element={<BestCryptoTools data1={forumbtc} data2={forumbtclist} />} />
              <Route path="/app/debito" element={<BestCryptoTools data1={debito} data2={debitolist} />} />
              <Route path="/app/DEX-DeFi-Swap" element={<BestCryptoTools data1={dex1} data2={dex1list} />} />
              <Route path="/app/airdrops" element={<BestCryptoTools data1={airdrops} data2={aidropslist} />} />
              <Route path="/app/IDO-ICO" element={<BestCryptoTools data1={IDOdata} data2={IDOList} />} />
              <Route path="/app/BRC-20" element={<BestCryptoTools data1={BRC20data} data2={BRC20list} />} />
              <Route path="/app/layer-2-blockchains" element={<BestCryptoTools data1={layer2} data2={layer2list} />} />
              <Route path="/app/revistas" element={<BestCryptoTools data1={revistas} data2={revistasList} />} />


              <Route path="/historia-dinero" element={<Iframe src={enlaces[0].url} />} />

              <Route path="/introducion-blockchain" element={<Iframe src={enlaces[1].url} />} />

              <Route path="/blockchain-industria" element={<Iframe src={enlaces[2].url} />} />

              <Route path="/btc-revolucion" element={<Iframe src={enlaces[3].url} />} />

              <Route path="/crypto" element={<Iframe src={enlaces[4].url} />} />

              <Route path="/tecnologia-blockchain" element={<Iframe src={enlaces[5].url} />} />

              <Route path="/web3" element={<Iframe src={enlaces[6].url} />} />

              <Route path="/mineria" element={<Iframe src={enlaces[7].url} />} />

              <Route path="/criptografia" element={<Iframe src={enlaces[8].url} />} />

              <Route path="/criptografia-asimetrica" element={<Iframe src={enlaces[9].url} />} />

              <Route path="/hash" element={<Iframe src={enlaces[10].url} />} />

              <Route path="/wallets" element={<Iframe src={enlaces[11].url} />} />

              <Route path="/smarkle-tree" element={<Iframe src={enlaces[12].url} />} />



              <Route path="/smart-contracts" element={<Iframe src={enlaces[13].url} />} />

              <Route path="/nft-formacion" element={<Iframe src={enlaces[14].url} />} />




              <Route path="/defi" element={<Iframe src={enlaces[16].url} />} />

              <Route path="/dapp" element={<Iframe src={enlaces[18].url} />} />








              <Route path="/aspectos-legales" element={<Iframe src={enlaces[15].url} />} />


              <Route path="/dapp" element={<Iframe src={enlaces[17].url} />} />

            </Routes>
          )}
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
