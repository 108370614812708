import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout/Layout';

// Estilos con styled-components
const Wrapper = styled.div`




    background-color: white;
    color: #fff;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    isplay: flex;
  justify-content: flex-start; /* Alinea los gráficos a la izquierda */
 width: 80vw; /* Ocupa el 75% del ancho de la pantalla */
  margin-left: auto; /* Alinea el contenido a la derecha */
  margin-right: 30px; 
  z-index=-10;
  

  nav {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #222;
    margin-bottom: 20px;
    width: 100%;
  }

  nav a {
    color: #ff1a1a;
    padding: 10px 15px;
    text-decoration: none;
    margin: 0 10px;
    font-size: 18px;
    text-transform: uppercase;
    border: 1px solid #ff1a1a;
    border-radius: 5px;
  }

  .chart-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Distribuye en 4 columnas */
    grid-gap: 20px;
    padding: 20px;
    width: 100%; /* Ocupa el 100% del área asignada */
  }

  .chart-wrapper {
    text-align: center;
    background-color: #111;
    padding: 10px;
    border-radius: 10px;
    
  }

  .info-bar {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  .percentage, .price, .strength {
    font-size: 16px;
    color: #ff4d4d;
  }

  .tradingview-widget-container {
    width: 100%;
    height: 200px;
  }

  .tradingview-widget-container__widget {
    border: none;
    border-radius: 5px;
  }
`;

// Componente que renderiza un gráfico de TradingView para cada criptomoneda
const TradingViewWidget = ({ symbol, percentage, price, strength }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/tv.js';
        script.async = true;
        script.onload = () => {
            new window.TradingView.widget({
                width: '100%',
                height: '200px',
                symbol: symbol,
                interval: '1',
                timezone: 'Etc/UTC',
                theme: 'dark',
                style: '1',
                locale: 'en',
                hide_side_toolbar: true,
                allow_symbol_change: false,
                hide_top_toolbar: true,
                hide_legend: true,
                withdateranges: false,
                save_image: false,
                container_id: symbol,
            });
        };
        document.getElementById(symbol).appendChild(script);
    }, [symbol]);

    return (

        <div className="chart-wrapper">
            <div className="info-bar">
                <div className="percentage">{percentage}</div>
                <div className="price">{price}</div>
                <div className="strength">{strength}</div>
            </div>
            <div className="tradingview-widget-container">
                <div id={symbol}></div>
            </div>
        </div>
    );
};

// Componente principal que recorre un array de criptomonedas
const CryptoCharts = () => {
    const cryptocurrencies = [
        { symbol: 'BINANCE:BTCUSDT', percentage: '+2.34%', price: 'BTC: $40,000', strength: 'Buy: 65%' },
        { symbol: 'BINANCE:ETHUSDT', percentage: '-1.76%', price: 'ETH: $3,000', strength: 'Sell: 70%' },
        { symbol: 'BINANCE:BNBUSDT', percentage: '+0.98%', price: 'BNB: $400', strength: 'Buy: 55%' },
        { symbol: 'BINANCE:ADAUSDT', percentage: '-0.45%', price: 'ADA: $2.00', strength: 'Sell: 60%' },
        { symbol: 'BINANCE:SOLUSDT', percentage: '+3.56%', price: 'SOL: $150', strength: 'Buy: 72%' },
        { symbol: 'BINANCE:XRPUSDT', percentage: '+1.12%', price: 'XRP: $1.10', strength: 'Buy: 60%' },
        { symbol: 'BINANCE:DOGEUSDT', percentage: '-2.23%', price: 'DOGE: $0.30', strength: 'Sell: 45%' },
        { symbol: 'BINANCE:DOTUSDT', percentage: '+0.85%', price: 'DOT: $35', strength: 'Buy: 55%' },
        { symbol: 'BINANCE:POLUSDT', percentage: '+2.89%', price: 'POL: $1.50', strength: 'Buy: 80%' }, // Polygon (antes Matic)
        { symbol: 'BINANCE:ATOMUSDT', percentage: '+4.10%', price: 'ATOM: $30', strength: 'Buy: 75%' }, // Cosmos
        { symbol: 'BINANCE:AVAXUSDT', percentage: '+3.56%', price: 'AVAX: $60', strength: 'Buy: 65%' }, // Avalanche
        { symbol: 'BINANCE:FTMUSDT', percentage: '+5.12%', price: 'FTM: $2.50', strength: 'Buy: 80%' }, // Fantom
        { symbol: 'BINANCE:NEARUSDT', percentage: '+4.78%', price: 'NEAR: $9.00', strength: 'Buy: 72%' }, // Near Protocol
        { symbol: 'BINANCE:AXSUSDT', percentage: '+7.34%', price: 'AXS: $120', strength: 'Buy: 85%' }, // Axie Infinity
        { symbol: 'BINANCE:SANDUSDT', percentage: '+6.10%', price: 'SAND: $5', strength: 'Buy: 70%' }, // The Sandbox
        { symbol: 'BINANCE:CRVUSDT', percentage: '+2.78%', price: 'CRV: $4.50', strength: 'Buy: 58%' }, // Curve DAO Token
        { symbol: 'BINANCE:DYDXUSDT', percentage: '+5.45%', price: 'DYDX: $12', strength: 'Buy: 80%' }, // dYdX
        { symbol: 'BINANCE:RUNEUSDT', percentage: '+4.00%', price: 'RUNE: $11', strength: 'Buy: 75%' }, // Thorchain
        { symbol: 'BINANCE:ALICEUSDT', percentage: '+5.70%', price: 'ALICE: $15', strength: 'Buy: 85%' }  // My Neighbor Alice
    ];

    return (
      
            <Wrapper>
                <nav>
                    <a href="#">Binance 1m</a>
                    <a href="#">Binance 5m</a>
                    <a href="#">Binance 15m</a>
                    <a href="#">MEXC 1m</a>
                    <a href="#">MEXC 5m</a>
                    <a href="#">MEXC 15m</a>
                </nav>

                <div className="chart-container">
                    {cryptocurrencies.map((crypto, index) => (
                        <TradingViewWidget
                            key={index}
                            symbol={crypto.symbol}
                            percentage={crypto.percentage}
                            price={crypto.price}
                            strength={crypto.strength}
                        />
                    ))}
                </div>
            </Wrapper>
       

    );
};

export default CryptoCharts;
