import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Countdown from '../elements/Countdown';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import 'swiper/css'; // Importación de estilos de Swiper

const shineAnimation = keyframes`
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
`;

const CardBox = styled.div`
  background-color: #e0f7ff; /* Fondo azul claro */
  border-radius: 12px; /* Bordes redondeados */
  overflow: hidden; /* Asegura que los bordes redondeados corten el contenido */
  position: relative; /* Necesario para el efecto de reflejo */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra ligera */
  transition: transform 0.3s ease-in-out; /* Suavizado en hover */
  text-align: center; /* Centrar texto */
  padding: 15px; /* Añadir padding para mejorar la presentación */

  &:hover {
    transform: translateY(-10px); /* Eleva la carta cuando se pasa el cursor */
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.1) 100%
    );
    transform: skewX(-25deg);
    transition: all 0.5s ease-in-out;
    animation: ${shineAnimation} 2s infinite ease-in-out; /* Animación del reflejo */
  }
`;

const CardMedia = styled.div`
  display: flex;
  justify-content: center; /* Centrar la imagen */
  margin-bottom: 15px;
  
  img {
    width: 95%; /* Reducir el tamaño de la imagen */
    height: auto;
    border-radius: 8px; /* Opcional: Bordes redondeados para la imagen */
  }
`;

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* Centrar el contenido */
  flex-direction: column; /* Colocar el avatar y el texto en columnas */
  margin-top: 10px;

  .avatar {
    margin-bottom: 10px; /* Espaciado entre el avatar y el texto */
  }

  img {
    width: 50px; /* Ajusta el tamaño del avatar */
    height: 50px;
    border-radius: 50%; /* Hacer el avatar circular */
    object-fit: cover;
  }

  .info {
    text-align: center; /* Centrar el texto */
  }
`;

const BottomButtons = styled.div`
  margin-top: 15px;
  text-align: center;

  a {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
  }
`;

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const CardSwiper = ({ slideData, handleBidModal, currentTime, swiperOptions }) => {
  const { t } = useTranslation();

  return (
    <Swiper {...swiperOptions}>
      {slideData.map((slide, index) => (
        <SwiperSlide key={index}>
          <CardBox>
            <CardMedia>
              <Link to="#">
                <img src={slide.image} alt="" />
              </Link>
            </CardMedia>
            <h5 className="name">
              <Link to="#">{slide.productInfo.name}</Link>
            </h5>
            <AuthorInfo>
              <div className="avatar">
                <img src={slide.author.avatar} alt="Avatar" />
              </div>
              <div className="info">
                <span>Creado Por:</span>
                <h6>
                  <Link to={slide.author.link}>{slide.author.name}</Link>
                </h6>
              </div>
            </AuthorInfo>
            <CountdownContainer>
              <Countdown endDateTime={new Date(currentTime).setDate(currentTime.getDate() + 2)} />
            </CountdownContainer>
            <BottomButtons>
              <Link to="#">{slide.productInfo.collection}</Link>
            </BottomButtons>
          </CardBox>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CardSwiper;
