import React from "react";

// Este es el componente para mostrar el mes y el año, con botones de navegación.
export class Months extends React.Component {
  constructor(props) {
    super(props);
    this.hadleClickLeft = this.hadleClickLeft.bind(this);
    this.hadleClickRigth = this.hadleClickRigth.bind(this);
  }

  // Función para retroceder al mes anterior
  hadleClickLeft(e) {
    this.props.onClick(-1);
  }

  // Función para avanzar al mes siguiente
  hadleClickRigth(e) {
    this.props.onClick(1);
  }

  render() {
    return (
      // Se retorna el mes y año con los botones de navegación
      <div className="Months">
        <button className="navButton" onClick={this.hadleClickLeft}> &lt; </button>
        <div className="monthYear">
          <h3>{this.props.month}, {this.props.year}</h3>
        </div>
        <button className="navButton" onClick={this.hadleClickRigth}> &gt; </button>

        <style>{`
          .Months {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #00bcd4; /* Color de fondo turquesa */
            padding: 10px;
            width: 100%;
            max-width: 400px; /* Limitar el ancho del contenedor */
            margin: 0 auto; /* Centrar el contenido horizontalmente */
          }

          .monthYear {
            text-align: center;
            font-family: "Cabin", sans-serif;
            font-size: 1.5rem; /* Tamaño de texto para el mes y año */
            color: black; /* Color de texto */
          }

          .navButton {
            background-color: white;
            color: black;
            border: 1px solid #00bcd4;
  
            width: 40px;
            height: 40px;
            font-size: 1.2rem;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }

          .navButton:hover {
            background-color: #00bcd4;
            color: white;
          }

          h3 {
            margin: 0; /* Eliminar margen alrededor del texto */
          }
        `}</style>
      </div>
    );
  }
}
