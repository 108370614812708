import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import 'bootstrap/dist/css/bootstrap.min.css';

const BootstrapCard = ({ title, subtopics, content }) => {
  const [activeContent, setActiveContent] = useState(content[subtopics[0]]);
  return (
    <div className="w-100">
      <h2 className="text-center"  style={{ color: '#0A5364' }}>{title}</h2>
      <Card className="mb-3 w-100" style={{ minHeight: '200px', maxHeight: '400px' }}>
        <Card.Header>
          <Nav variant="tabs" className="justify-content-center">
            {subtopics.map((subtopic, idx) => (
              <Nav.Item key={idx} className="mx-1">
                <Nav.Link
                  href={`#${subtopic.replace(/\s/g, '')}`}
                  style={{ color: '#0A5364', fontSize: '1.2rem', whiteSpace: 'nowrap' }} // Siempre mostrar completo el título
                  onClick={() => setActiveContent(content[subtopic])}
                >
                  {subtopic} {/* No truncar el título */}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body className="text-center">
          <Card.Text>{activeContent}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

const CardContainer = () => {
  const [activeTopic, setActiveTopic] = useState(0);

  const topics = [
    {
      title: "Fundamentos Blockchain",
      subtopics: ["Definición Blockchain", "Historia Blockchain", "Funcionamiento Blockchain", "Tipos Blockchain", "Aplicaciones Reales"],
      content: {
        "Definición Blockchain": "Blockchain es una tecnología de contabilidad distribuida que asegura transparencia y descentralización mediante nodos en red. La inmutabilidad de los datos garantiza que la información almacenada no pueda ser alterada, fortaleciendo la seguridad y confiabilidad del sistema.",
        "Historia Blockchain": "Desde la creación de Bitcoin en 2008, blockchain ha evolucionado con Ethereum y otras redes que han introducido conceptos como contratos inteligentes y soluciones para escalabilidad. Estos avances han hecho que blockchain sea aplicable en muchos más sectores.",
        "Funcionamiento Blockchain": "El funcionamiento de blockchain se basa en la validación de transacciones por nodos distribuidos, agrupándolas en bloques. Los algoritmos de consenso, como Proof of Work (PoW) y Proof of Stake (PoS), son fundamentales para garantizar la seguridad y evitar ataques maliciosos.",
        "Tipos Blockchain": "Existen blockchains públicas (como Bitcoin y Ethereum), privadas (usadas por empresas) y consorciadas (usadas por grupos de empresas). Cada tipo tiene sus aplicaciones dependiendo de los casos de uso, desde criptomonedas hasta trazabilidad en cadenas de suministro.",
        "Aplicaciones Reales": "Blockchain ya está transformando sectores como las finanzas con criptomonedas, los contratos inteligentes en bienes raíces, la trazabilidad de productos en cadenas de suministro, y la gestión pública en algunos gobiernos, aportando mayor eficiencia y transparencia."
      }
    },
    {
      title: "Contratos Tokenización",
      subtopics: ["Creación Contratos", "Seguridad Contratos", "Implementación Contratos", "Tipos en Tokenización", "Contrato KaU"],
      content: {
        "Creación Contratos": "Escribir contratos inteligentes con Solidity requiere definir variables, estructuras y métodos para gestionar transacciones. Un aspecto clave es la optimización del gas para evitar costos innecesarios durante la ejecución de contratos en la red Ethereum.",
        "Seguridad Contratos": "Las vulnerabilidades en contratos inteligentes, como el ataque de reentrancy, pueden ser evitadas con buenas prácticas de diseño. Patrones como el de guardián y la implementación de pruebas exhaustivas ayudan a prevenir errores que podrían comprometer los fondos.",
        "Implementación Contratos": "Desplegar un contrato inteligente implica primero testearlo en redes de prueba como Ropsten o BSC Testnet. Una vez validado, se puede desplegar en redes principales como Ethereum Mainnet o Binance Smart Chain, asegurando su funcionalidad.",
        "Tipos en Tokenización": "Los contratos en tokenización siguen estándares como ERC-20 para tokens fungibles, ERC-721 para tokens no fungibles (NFTs) y ERC-1155 para la combinación de ambos. Estos se usan para tokenizar desde activos financieros hasta bienes inmuebles y arte.",
        "Contrato KaU": "El contrato KaU es una implementación específica para plataformas de tokenización que permite gestionar la emisión, compra y venta de tokens asociados a un proyecto. Su estructura garantiza la protección de inversores y la transparencia en la gestión."
      }
    },
    {
      title: "DeFi Tokenización",
      subtopics: ["Fundamentos DeFi", "Impacto en DeFi", "Protocolos DeFi", "Pools Liquidez", "Yield Farming"],
      content: {
        "Fundamentos DeFi": "Las Finanzas Descentralizadas (DeFi) permiten replicar servicios financieros tradicionales, como préstamos y seguros, sin intermediarios. Utilizan contratos inteligentes para garantizar la seguridad y ofrecer servicios globales accesibles desde cualquier lugar.",
        "Impacto en DeFi": "La tokenización ha revolucionado el DeFi al crear activos líquidos que se pueden intercambiar, prestar o usar como garantía. Esto ha abierto la puerta a productos financieros innovadores y ha facilitado la inclusión financiera.",
        "Protocolos DeFi": "Protocolos como Uniswap, Compound y Aave han permitido crear mercados descentralizados donde los usuarios pueden intercambiar activos, obtener préstamos o hacer staking. Estos protocolos operan sin intermediarios y son gobernados por sus propios usuarios.",
        "Pools Liquidez": "Los pools de liquidez son esenciales en el ecosistema DeFi, permitiendo a los usuarios depositar activos en contratos inteligentes para facilitar el intercambio de tokens. A cambio, los proveedores de liquidez reciben comisiones y tokens de recompensa.",
        "Yield Farming": "El yield farming es una técnica de inversión en la que los usuarios depositan activos en protocolos DeFi a cambio de intereses o recompensas. Aunque puede generar altos rendimientos, también conlleva riesgos como la impermanence loss."
      }
    },
    {
      title: "DAO Blockchain",
      subtopics: ["Definición DAO", "Tipos de DAO", "Gobernanza DAO", "Casos de DAO", "Ventajas DAO"],
      content: {
        "Definición DAO": "Una Organización Autónoma Descentralizada (DAO) opera sin un control centralizado, utilizando contratos inteligentes para regular su funcionamiento. Las decisiones dentro de una DAO son tomadas por los participantes mediante votación.",
        "Tipos de DAO": "Existen diferentes tipos de DAOs, como las DAOs de inversión, donde los usuarios deciden en qué proyectos invertir, y las DAOs de gobernanza, que permiten a los usuarios tomar decisiones sobre el desarrollo de un protocolo o proyecto.",
        "Gobernanza DAO": "La gobernanza en una DAO se basa en la posesión de tokens que otorgan derechos de voto. Los participantes pueden proponer cambios y votarlos, lo que da lugar a una gestión descentralizada y sin intermediarios de la organización.",
        "Casos de DAO": "Proyectos como MakerDAO y Aragon han demostrado que las DAOs pueden ser efectivas en la toma de decisiones colectivas. Estos casos muestran cómo la descentralización puede aplicarse a las finanzas y la gobernanza sin depender de autoridades centrales.",
        "Ventajas DAO": "Las DAOs ofrecen transparencia, descentralización y automatización, eliminando la necesidad de intermediarios. Sin embargo, también presentan retos regulatorios y de seguridad, como el riesgo de vulnerabilidades en los contratos inteligentes que las controlan."
      }
    },
    {
      title: "ICOs y STOs",
      subtopics: ["Definición ICO", "Diferencias ICO-STO", "Ventajas ICO", "Regulación ICO", "Riesgos ICO"],
      content: {
        "Definición ICO": "Las ofertas iniciales de monedas (ICO) son una forma de crowdfunding en blockchain que permite a los emprendedores recaudar fondos para sus proyectos mediante la venta de tokens a inversores. Estos tokens pueden representar derechos sobre un producto o servicio futuro.",
        "Diferencias ICO-STO": "A diferencia de las ICO, las STO (Ofertas de Tokens de Seguridad) cumplen con regulaciones financieras más estrictas, ofreciendo tokens que representan valores financieros. Esto ofrece mayor seguridad legal a los inversores, pero reduce la accesibilidad global.",
        "Ventajas ICO": "Las ICO permiten la recaudación de fondos a nivel global, brindando oportunidades a proyectos que de otra manera no obtendrían financiamiento. Los inversores pueden participar en proyectos innovadores desde su etapa inicial, con un bajo costo de entrada.",
        "Regulación ICO": "La regulación de ICOs varía según la jurisdicción. Mientras algunos países las permiten con restricciones mínimas, otros las prohíben o las someten a estrictas regulaciones para proteger a los inversores y prevenir fraudes.",
        "Riesgos ICO": "A pesar de su atractivo, las ICOs conllevan riesgos considerables, como la falta de regulación, la alta volatilidad de los tokens y el potencial de estafas. Muchos proyectos fracasan o no cumplen sus promesas, dejando a los inversores con pérdidas."
      }
    },
    {
      title: "KYC en Tokenización",
      subtopics: ["Importancia KYC", "Proceso KYC", "Requisitos Legales", "Impacto en Tokenización", "Seguridad KYC"],
      content: {
        "Importancia KYC": "Know Your Customer (KYC) es un proceso obligatorio en la mayoría de las plataformas de tokenización, que ayuda a verificar la identidad de los usuarios, previniendo el lavado de dinero y cumpliendo con las normativas legales de cada jurisdicción.",
        "Proceso KYC": "El proceso de KYC generalmente incluye la verificación de documentos como pasaportes o licencias de conducir, y la validación de la identidad a través de videos o selfies en tiempo real. Algunas plataformas también solicitan prueba de residencia.",
        "Requisitos Legales": "Las normativas globales de KYC varían, pero en general, exigen que las plataformas cumplan con leyes anti-lavado de dinero (AML) y protección contra la financiación del terrorismo. Las empresas deben cumplir con auditorías regulares para mantener su conformidad.",
        "Impacto en Tokenización": "El proceso de KYC impacta directamente en la tokenización, limitando el acceso a inversores que no cumplan con los requisitos legales de cada país. Aunque esto puede reducir el mercado potencial, también aumenta la confianza y la seguridad de los participantes.",
        "Seguridad KYC": "Las plataformas deben asegurar que la información personal recopilada en los procesos de KYC esté adecuadamente protegida, implementando medidas como cifrado de datos, almacenamiento seguro y auditorías para prevenir brechas de seguridad."
      }
    },
    {
      title: "Normativas Tokenización",
      subtopics: ["Regulaciones Globales", "Cumplimiento Normativo", "Requisitos Tokenización", "Leyes Seguridad", "Retos Legales"],
      content: {
        "Regulaciones Globales": "Las regulaciones en la tokenización varían ampliamente según la jurisdicción, con algunos países favoreciendo la innovación, mientras que otros imponen restricciones estrictas. Las empresas deben estar al tanto de las leyes aplicables en cada mercado donde operan.",
        "Cumplimiento Normativo": "El cumplimiento de normativas es esencial para garantizar que los proyectos de tokenización operen dentro de los límites legales. Esto incluye el cumplimiento de leyes sobre valores, protección de inversores, y regulaciones contra el lavado de dinero (AML).",
        "Requisitos Tokenización": "Los proyectos de tokenización deben cumplir con requisitos específicos dependiendo del tipo de activos que representen los tokens, ya sean inmobiliarios, financieros o de otro tipo. Estos requisitos incluyen auditorías y certificaciones de conformidad.",
        "Leyes Seguridad": "Las leyes de seguridad en la tokenización exigen que las plataformas garanticen la protección de los datos de los usuarios y la integridad de los contratos inteligentes. El uso de encriptación y auditorías regulares es clave para el cumplimiento de estas leyes.",
        "Retos Legales": "Los proyectos de tokenización enfrentan diversos retos legales, desde la determinación de la jurisdicción aplicable, hasta la gestión de las leyes fiscales y de propiedad. La naturaleza transnacional de los proyectos añade complejidad a su implementación."
      }
    },
    {
      title: "Modelos en Tokenización",
      subtopics: ["Modelos Fraccionados", "Activos Financieros", "Propiedades Inmuebles", "Tokenización Arte", "Normativa de Tokenización"],
      content: {
        "Modelos Fraccionados": "La tokenización fraccionada permite dividir un activo en múltiples tokens, ofreciendo una forma accesible de invertir en propiedades de alto valor, como bienes raíces, arte o activos financieros. Cada token representa una parte proporcional del activo.",
        "Activos Financieros": "La tokenización de activos financieros, como acciones, bonos y fondos de inversión, mejora la liquidez y democratiza el acceso a estos instrumentos. Los inversores pueden comprar y vender tokens que representan una participación en los activos subyacentes.",
        "Propiedades Inmuebles": "La tokenización de bienes inmuebles ha transformado el mercado inmobiliario, permitiendo a los inversores comprar una participación en propiedades sin necesidad de adquirir la propiedad completa. Esto facilita la diversificación y la inversión global.",
        "Tokenización Arte": "El arte tokenizado permite a los inversores poseer una parte de obras de arte de alto valor, que de otro modo estarían fuera de su alcance. Los tokens representan una fracción del valor de la obra, y los propietarios pueden negociar estos tokens en mercados secundarios.",
        "Normativa de Tokenización": "La regulación de la tokenización varía según el tipo de activo y la jurisdicción. Los proyectos de tokenización deben cumplir con las leyes locales sobre valores y protección de inversores, asegurando que sus operaciones sean legales y seguras."
      }
    },
    {
      title: "Desafíos de Tokenización",
      subtopics: ["Retos Legales", "Seguridad Blockchain", "Auditorías Regulares", "Gobernanza Descentralizada", "Escalabilidad Redes"],
      content: {
        "Retos Legales": "Los proyectos de tokenización enfrentan desafíos legales relacionados con la clasificación de tokens como valores, las leyes de propiedad digital y las normativas fiscales. Estos retos pueden retrasar la implementación o limitar la adopción global.",
        "Seguridad Blockchain": "La seguridad es un factor crítico en los proyectos de tokenización. La vulnerabilidad de los contratos inteligentes y las posibles fallas en la infraestructura blockchain pueden poner en riesgo los activos tokenizados, haciendo que la auditoría y pruebas sean esenciales.",
        "Auditorías Regulares": "Para garantizar la confianza de los inversores, los proyectos de tokenización deben someterse a auditorías regulares de sus contratos inteligentes y procesos de KYC. Las auditorías ayudan a identificar vulnerabilidades y asegurar el cumplimiento normativo.",
        "Gobernanza Descentralizada": "Los proyectos que implementan gobernanza descentralizada a través de DAOs deben lidiar con la complejidad de tomar decisiones colectivas y gestionar los fondos sin un control centralizado. Esto requiere una planificación detallada para evitar conflictos y fraudes.",
        "Escalabilidad Redes": "La escalabilidad es uno de los principales desafíos de la tokenización en blockchain. Las redes actuales, como Ethereum, enfrentan problemas de congestión y altos costos de gas que limitan la adopción masiva. Las soluciones de capa 2 y nuevas blockchains buscan resolver este problema."
      }
      
    },
    {
      title: "Seguridad Wallets",
      subtopics: ["Tipos de Wallets", "Seguridad Hot Wallets", "Seguridad Cold Wallets", "Exchanges Centralizados", "Mejores Prácticas"],
      content: {
        "Tipos de Wallets": "Las wallets en el ecosistema blockchain se dividen en dos categorías principales: hot wallets (conectadas a internet) y cold wallets (almacenadas offline). Cada una tiene ventajas y desventajas, dependiendo de su uso y nivel de seguridad requerido.",
        "Seguridad Hot Wallets": "Las hot wallets son convenientes para el uso diario y permiten acceder rápidamente a los fondos, pero son más vulnerables a ataques cibernéticos. Para mitigar riesgos, se recomienda usar contraseñas fuertes, autenticación de dos factores (2FA), y no almacenar grandes cantidades de fondos.",
        "Seguridad Cold Wallets": "Cold wallets, como hardware wallets o paper wallets, ofrecen una mayor seguridad al mantenerse desconectadas de internet, reduciendo significativamente el riesgo de hacks. Sin embargo, deben protegerse de daños físicos y robos. Su uso es ideal para almacenar grandes cantidades de criptomonedas.",
        "Exchanges Centralizados": "Los exchanges centralizados (CEX), como Binance o Coinbase, actúan como intermediarios para comprar, vender y almacenar criptomonedas. Aunque ofrecen una experiencia fácil de usar, el control sobre los fondos depende del exchange, lo que los hace un objetivo atractivo para los hackers.",
        "Mejores Prácticas": "Entre las mejores prácticas de seguridad para wallets está el uso de backups seguros de las claves privadas, el uso de wallets con multi-firma, y verificar siempre la autenticidad de los sitios web o aplicaciones antes de acceder a ellos. Además, es fundamental educarse sobre los riesgos asociados a cada tipo de wallet."
      }
    },
    {
      title: "Seguridad en NFTs",
      subtopics: ["Definición NFTs", "Riesgos en NFTs", "Almacenamiento Seguro", "Mercados NFT", "Protección Propiedad"],
      content: {
        "Definición NFTs": "Los Non-Fungible Tokens (NFTs) son activos digitales únicos que representan la propiedad de bienes digitales como arte, música, o propiedades virtuales. Su popularidad ha crecido, pero al ser activos valiosos, también atraen amenazas de seguridad.",
        "Riesgos en NFTs": "Los riesgos más comunes en NFTs incluyen la pérdida de claves privadas, vulnerabilidades en los contratos inteligentes que manejan los NFTs, y fraudes en plataformas de venta. Los inversores deben investigar las plataformas y contratos antes de comprar.",
        "Almacenamiento Seguro": "Al igual que las criptomonedas, los NFTs deben almacenarse en wallets seguras. Se recomienda el uso de cold wallets para almacenar NFTs valiosos y evitar su exposición a ataques cibernéticos. Algunos propietarios también optan por distribuir sus activos entre varias wallets.",
        "Mercados NFT": "Los mercados NFT, como OpenSea, permiten la compra, venta y transferencia de NFTs. Sin embargo, los usuarios deben tener cuidado con los listados falsos o clonados y asegurarse de que las plataformas implementen medidas de seguridad sólidas.",
        "Protección Propiedad": "La propiedad de un NFT está vinculada a la clave privada de la wallet que lo posee. Si esta clave se pierde, también se pierde el acceso al NFT. Para proteger los derechos de propiedad, es importante realizar backups de las claves y no compartirlas con terceros."
      }
    },
    {
      title: "Exchanges Descentralizados",
      subtopics: ["Definición DEX", "Seguridad en DEX", "Liquidez en DEX", "Anonimato y Regulación", "Mejores Prácticas DEX"],
      content: {
        "Definición DEX": "Un exchange descentralizado (DEX) es una plataforma que permite a los usuarios intercambiar criptomonedas directamente entre ellos, sin intermediarios. Uniswap y PancakeSwap son ejemplos populares de DEXs, que funcionan a través de contratos inteligentes.",
        "Seguridad en DEX": "Al no depender de un intermediario centralizado, los DEXs ofrecen mayor privacidad y autonomía. Sin embargo, los usuarios son responsables de la seguridad de sus fondos. Es crucial verificar la autenticidad de los contratos inteligentes utilizados por la plataforma para evitar estafas.",
        "Liquidez en DEX": "Los DEXs dependen de pools de liquidez, donde los usuarios pueden depositar tokens para facilitar el comercio. A cambio, los proveedores de liquidez ganan comisiones. La falta de liquidez puede dificultar los intercambios, y algunos DEXs incentivan la liquidez mediante recompensas.",
        "Anonimato y Regulación": "Los DEXs ofrecen anonimato al no requerir procesos KYC. Sin embargo, esto también ha generado preocupaciones regulatorias. Algunos gobiernos buscan regular los DEXs para prevenir actividades ilícitas, lo que podría cambiar su funcionamiento en el futuro.",
        "Mejores Prácticas DEX": "Para operar de manera segura en DEXs, los usuarios deben investigar los contratos inteligentes que ejecutan las transacciones, utilizar wallets seguras y estar al tanto de las fluctuaciones de liquidez y comisiones para evitar pérdidas innecesarias."
      }
    },
    {
      title: "Sistemas Multi-Firma",
      subtopics: ["Definición Multi-Firma", "Aplicaciones en Seguridad", "Usos en DAO", "Ventajas de Multi-Firma", "Riesgos Asociados"],
      content: {
        "Definición Multi-Firma": "Una wallet multi-firma requiere la autorización de varias partes para aprobar una transacción. Este enfoque distribuye el control de los fondos, haciendo que el robo de fondos sea mucho más difícil que en una wallet convencional.",
        "Aplicaciones en Seguridad": "Las wallets multi-firma son comunes en proyectos que manejan grandes cantidades de criptomonedas, como exchanges y DAOs. También son útiles en la custodia de activos valiosos, permitiendo un control distribuido y mayor protección contra ataques.",
        "Usos en DAO": "Las DAOs utilizan wallets multi-firma para gestionar los fondos colectivos. Este sistema asegura que ninguna persona pueda acceder o mover los fondos sin la aprobación de otros miembros, garantizando la transparencia y seguridad de los recursos.",
        "Ventajas de Multi-Firma": "Una de las principales ventajas de las wallets multi-firma es la reducción del riesgo de pérdidas debido a errores humanos o robos. Además, su uso mejora la transparencia en la gestión de fondos en proyectos grupales o empresariales.",
        "Riesgos Asociados": "A pesar de sus ventajas, las wallets multi-firma también presentan desafíos, como la complejidad en su configuración y la necesidad de coordinarse entre las partes autorizadas para aprobar transacciones. Si una parte pierde su clave, puede haber dificultades para acceder a los fondos."
      }
    },
    {
      title: "Seguridad en Exchanges",
      subtopics: ["Custodia en Exchanges", "Ataques a Exchanges", "Exchanges Descentralizados", "Regulaciones Exchange", "Seguridad de Claves"],
      content: {
        "Custodia en Exchanges": "La mayoría de los exchanges centralizados custodian las claves privadas de los usuarios, lo que significa que los fondos están controlados por el exchange. Esto puede ser conveniente, pero también aumenta el riesgo en caso de que el exchange sea hackeado.",
        "Ataques a Exchanges": "Históricamente, muchos exchanges han sido objetivo de grandes ataques, como el caso de Mt. Gox. Los usuarios deben ser conscientes de los riesgos asociados a mantener fondos en exchanges centralizados y considerar moverlos a wallets personales después de realizar intercambios.",
        "Exchanges Descentralizados": "Los exchanges descentralizados ofrecen mayor seguridad al no custodiar los fondos de los usuarios. Sin embargo, estos dependen de contratos inteligentes, y las vulnerabilidades en dichos contratos también pueden comprometer la seguridad de los fondos.",
        "Regulaciones Exchange": "Los exchanges deben cumplir con las regulaciones KYC y AML en la mayoría de los países, lo que implica que los usuarios deben verificar su identidad antes de operar. Este proceso añade una capa de seguridad, pero también reduce la privacidad y el anonimato.",
        "Seguridad de Claves": "La seguridad de las claves privadas es crucial en cualquier plataforma de intercambio. Los usuarios deben implementar medidas adicionales, como usar hardware wallets para almacenar grandes cantidades y evitar compartir información sensible con terceros."
      }
    }
  ];

  return (
    <div className="container">
      <div className="d-flex justify-content-between w-100">
        <ListGroup horizontal className="w-100 justify-content-center">
          {topics.map((topic, index) => (
            <ListGroup.Item
            key={index}
            action
            onClick={() => setActiveTopic(index)}
            active={index === activeTopic}
            className="text-center"
            style={{
              fontSize: '1rem',
              padding: '15px',
              maxWidth: '200px',
              backgroundColor: index === activeTopic ? '#0A5364' : '#ffffff', // Color activo turquesa
              color: index === activeTopic ? '#fff' : '#0A5364', // Color de texto claro en activo
              borderColor: '#0A5364', // Borde turquesa
              whiteSpace: 'nowrap', // Evita que el texto se rompa en varias líneas
              overflow: 'hidden', // Oculta el texto que se desborda
              textOverflow: 'ellipsis', // Muestra '...' cuando el texto se desborda
              display: 'block' // Asegura que el estilo de bloque se aplique correctamente
            }}
          >
            {topic.title}
          </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
      <div className="mt-3 w-100">
        <BootstrapCard
          title={topics[activeTopic].title}
          subtopics={topics[activeTopic].subtopics}
          content={topics[activeTopic].content}
        />
      </div>
    </div>
  );
};

export default CardContainer;
