import React  from "react";

const Roadmap = () => {
    const points = [
        {
            index: 1,
            label: 'Fundamentos de Blockchain',
            text: 'Introducción a la tecnología blockchain, sus principios básicos y componentes arquitectónicos.',
            hours: 2,
        },
        {
            index: 2,
            label: 'Economía de los Tokens',
            text: 'Exploración de la economía de los tokens, incluyendo la creación, distribución y los diferentes tipos de tokens.',
            hours: 2,
        },
        {
            index: 3,
            label: 'Tokenización de Activos',
            text: 'Cómo tokenizar activos reales y digitales, y las implicaciones legales y económicas de la tokenización.',
            hours: 2,
        },
        {
            index: 4,
            label: 'Seguridad en Blockchain',
            text: 'Estudio de las medidas de seguridad necesarias en blockchain, incluyendo la gestión de claves y mitigación de riesgos.',
            hours: 2,
        },
        {
            index: 5,
            label: 'Contratos Inteligentes y Legalidad',
            text: 'Análisis profundo de contratos inteligentes, su implementación y los aspectos legales relacionados.',
            hours: 2,
        },
        {
            index: 6,
            label: 'Desarrollo de Proyectos de Tokenización',
            text: 'Métodos y herramientas para desarrollar proyectos de tokenización desde la concepción hasta el despliegue.',
            hours: 2,
        },
        {
            index: 7,
            label: 'Cumplimiento Regulatorio',
            text: 'Comprensión del entorno regulatorio global y cómo asegurar el cumplimiento en proyectos blockchain.',
            hours: 2,
        },
        {
            index: 8,
            label: 'Marketing y Construcción de Comunidad',
            text: 'Estrategias para el marketing efectivo y la construcción de comunidades sólidas en torno a proyectos blockchain.',
            hours: 2,
        },
        {
            index: 9,
            label: 'Trading y Exchanges',
            text: 'Principios básicos del trading de criptomonedas y cómo funcionan los exchanges.',
            hours: 2,
        },
        {
            index: 10,
            label: 'NFTs: Creación y Comercio',
            text: 'Exploración de Non-Fungible Tokens, cómo se crean, se venden, y su impacto en diversas industrias.',
            hours: 2,
        },
        {
            index: 11,
            label: 'Transacciones y Wallets',
            text: 'Detalles sobre cómo realizar transacciones seguras en blockchain y los diferentes tipos de wallets disponibles.',
            hours: 2,
        },
        {
            index: 12,
            label: 'Aplicaciones prácticas de la Tokenización',
            text: 'Estudio de casos prácticos y aplicaciones reales de la tokenización en diferentes sectores.',
            hours: 2,
        },
        {
            index: 13,
            label: 'Riesgos y Beneficios de la Blockchain',
            text: 'Evaluación crítica de los riesgos y beneficios asociados con la implementación de tecnologías blockchain.',
            hours: 2,
        },
        {
            index: 14,
            label: 'Tecnologías Emergentes en Blockchain',
            text: 'Visión general de las tecnologías emergentes en el ámbito de la blockchain y sus potenciales futuros desarrollos.',
            hours: 2,
        },
        {
            index: 15,
            label: 'Cierre y Proyecto Final',
            text: 'Integración de conocimientos adquiridos en un proyecto final que sintetiza los principales aspectos del curso.',
            hours: 2,
        },
    ];

    return (
        <div className="roadmap">
            {points.map((point) => (
                <div key={point.index} className="point">
                    <div className="point-index">
                        <span style={{ color: "#fff" }}>
                            {point.index}
                        </span>
                    </div>
                    <div className="point-label">
                        <span>
                            {point.label}
                        </span>
                    </div>
                    <div className="point-text" style={{ color: "#000" }}>
                        {point.text}
                    </div>
                </div>
            ))}
            
        </div>
    );
};

export default Roadmap;
